import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { IPopulationCount } from 'interfaces/fish-migration';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeListSelectedIds, fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';
import CommonCollapse from './components/CommonCollapse';

interface IOption {
  value: SelectOptionEnum;
  label: string;
}

enum SelectOptionEnum {
  SELECT_ALL = 'SELECT_ALL',
  UNSELECT_ALL = 'UNSELECT_ALL',
}

interface IdSelectProps {
  filteredDateRange: IPopulationCount[];
}

const IdSelect: FC<IdSelectProps> = ({ filteredDateRange }) => {
  const [selectedManageBox, setSelectedManageBox] = useState<SelectOptionEnum[]>([SelectOptionEnum.SELECT_ALL]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    aquaticSpeciesTracking: { listSelectedIds },
  } = useAppSelector(fishMigrationSelector);

  const listTagIds = useMemo(() => [...new Set(filteredDateRange.map((item) => item.tagId))], [filteredDateRange]);

  const isShowManageOption = listTagIds.length > 1;

  // enable all ids in the first time
  useEffect(() => {
    dispatch(changeListSelectedIds([...listTagIds]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const listTagIdOptions = listTagIds.map((item) => ({
    value: item,
    label: item,
  }));

  const listManageBoxOptions = [
    { value: SelectOptionEnum.SELECT_ALL, label: t('trans.select_all') },
    { value: SelectOptionEnum.UNSELECT_ALL, label: t('trans.unselect_all') },
  ];

  const handleSelectCheckbox = (e: ChangeEvent<HTMLInputElement>, value: number) => {
    const checked = e.target.checked;
    if (checked) {
      const newSelectedIds = [...listSelectedIds, value];
      dispatch(changeListSelectedIds(newSelectedIds));
      setSelectedManageBox((prev) => prev.filter((item) => item !== SelectOptionEnum.UNSELECT_ALL));

      if (newSelectedIds.length === listTagIds.length) {
        setSelectedManageBox([SelectOptionEnum.SELECT_ALL]);
      }
    } else {
      dispatch(changeListSelectedIds(listSelectedIds.filter((item) => item !== value)));
      setSelectedManageBox((prev) => prev.filter((item) => item !== SelectOptionEnum.SELECT_ALL));
    }
  };

  const handleSelectManageBox = (e: ChangeEvent<HTMLInputElement>, value: SelectOptionEnum) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedManageBox([value]);
      if (value === SelectOptionEnum.UNSELECT_ALL) {
        dispatch(changeListSelectedIds([]));
      } else if (value === SelectOptionEnum.SELECT_ALL) {
        dispatch(changeListSelectedIds([...listTagIds]));
      }
    }
  };

  const renderManageBoxOption = (item: IOption) => {
    return (
      <FormControlLabel
        key={item.value}
        sx={{ height: '28px' }}
        control={
          <Checkbox
            checked={selectedManageBox.includes(item.value)}
            onChange={(e) => handleSelectManageBox(e, item.value)}
          />
        }
        label={item.label}
      />
    );
  };

  return (
    <Box sx={{ mt: '8px' }}>
      <CommonCollapse label={t('trans.id')}>
        {isShowManageOption && listManageBoxOptions.slice(0, 1).map((item) => renderManageBoxOption(item))}
        {listTagIdOptions.map((item) => (
          <Stack>
            <FormControlLabel
              key={item.value}
              sx={{ height: '28px' }}
              control={
                <Checkbox
                  checked={listSelectedIds.includes(item.value)}
                  onChange={(e) => handleSelectCheckbox(e, item.value)}
                />
              }
              label={item.label}
            />
          </Stack>
        ))}

        {isShowManageOption && listManageBoxOptions.slice(-1).map((item) => renderManageBoxOption(item))}
      </CommonCollapse>
    </Box>
  );
};

export default IdSelect;
