import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { OPTION_ALL, OPTION_NONE } from 'common/defines/constants';
import { groupPopulationCountWithTagId } from 'common/utils/fish-migration';
import { DATE_STANDARD_FORMAT, DATE_VISUALIZE_FORMAT } from 'constants/date';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeFilteredEndDate,
  changeFilteredStartDate,
  fishMigrationSelector,
} from 'store/slices/map-view/fishMigrationAnalytics';

interface SelectDateAreaProps {
  filteredPopulationData: IPopulationCount[];
}

const SelectDateArea: FC<SelectDateAreaProps> = ({ filteredPopulationData }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    populationCount: { filteredStartDate, filteredEndDate },
  } = useAppSelector(fishMigrationSelector);

  const selectedStartDate = filteredStartDate.label;
  const selectedEndDate = filteredEndDate.label;

  const populationCountGroupedWithTagId = groupPopulationCountWithTagId(filteredPopulationData);

  const listStartDates = Object.values(populationCountGroupedWithTagId).map((item) => {
    return item[0]?.date;
  });

  const sortedListStartDates = [...listStartDates].sort((a, b) => {
    return moment(a, DATE_STANDARD_FORMAT).diff(moment(b, DATE_STANDARD_FORMAT));
  });

  const listUniqueStartDates = [...new Set(sortedListStartDates)].map((item) => ({
    label: moment(item).format(DATE_VISUALIZE_FORMAT),
    value: moment(item).format(DATE_STANDARD_FORMAT),
  }));

  const finalListStartDateOptions = [
    { value: OPTION_NONE, label: t('trans.none') },
    { value: OPTION_ALL, label: t('trans.all') },
    ...listUniqueStartDates,
  ];

  const handleChangeStartDate = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    if (value === OPTION_NONE) {
      dispatch(
        changeFilteredStartDate({
          label: OPTION_NONE,
          value: '',
        })
      );
    } else if (value === OPTION_ALL) {
      dispatch(
        changeFilteredStartDate({
          label: OPTION_ALL,
          value: listUniqueStartDates[0].value,
        })
      );
    } else {
      dispatch(
        changeFilteredStartDate({
          label: value,
          value: value,
        })
      );
    }
  };

  const listEndDates = Object.values(populationCountGroupedWithTagId)
    .map((item) => {
      if (item.length === 1) return item;
      return item.slice(1);
    })
    .flat()
    .map((item) => item.date)
    .filter((item) => {
      if (!filteredStartDate.value) {
        return true;
      }
      return moment(item).isAfter(filteredStartDate.value);
    });
  const sortedListEndDates = [...listEndDates].sort((a, b) => {
    return moment(a, DATE_STANDARD_FORMAT).diff(moment(b, DATE_STANDARD_FORMAT));
  });

  const listUniqueEndDates = [...new Set(sortedListEndDates)].map((item) => ({
    label: moment(item).format(DATE_VISUALIZE_FORMAT),
    value: moment(item).format(DATE_STANDARD_FORMAT),
  }));
  const finalListEndDateOptions = [
    { value: OPTION_NONE, label: t('trans.none') },
    { value: OPTION_ALL, label: t('trans.all') },
    ...listUniqueEndDates,
  ];

  const handleChangeEndDate = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    if (value === OPTION_NONE) {
      dispatch(
        changeFilteredEndDate({
          label: OPTION_NONE,
          value: '',
        })
      );
    } else if (value === OPTION_ALL) {
      dispatch(
        changeFilteredEndDate({
          label: OPTION_ALL,
          value: listUniqueEndDates[listUniqueEndDates.length - 1].value,
        })
      );
    } else {
      dispatch(
        changeFilteredEndDate({
          label: value,
          value: value,
        })
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '16px', mt: '16px' }}>
      <Box sx={{ display: 'flex', gap: '0px', width: '50%' }}>
        <Typography>{t('trans.start_date')}</Typography>
        <Select
          id="select-start-date"
          value={selectedStartDate}
          onChange={handleChangeStartDate}
          size="small"
          sx={{ width: '100%' }}>
          {finalListStartDateOptions.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box sx={{ display: 'flex', gap: '0px', width: '50%' }}>
        <Typography>{t('trans.end_date')}</Typography>
        <Select
          id="select-end-date"
          value={selectedEndDate}
          onChange={handleChangeEndDate}
          size="small"
          sx={{ width: '100%' }}>
          {finalListEndDateOptions.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </Box>
  );
};

export default SelectDateArea;
