import { MousePositionEnum } from 'common/defines/constants';
import { MouseEvent } from 'react';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface ISize {
  height: number;
  width: number;
}

interface Props {
  fixedSize?: ISize;
}

const defaultFixedSize = {
  height: 135,
  width: 290,
};

const useCheckingPopupPosition = ({ fixedSize = defaultFixedSize }: Props) => {
  const { drawerShow } = useAppSelector(mapViewSelector);

  const detectMousePosition = (e: MouseEvent<HTMLElement>) => {
    // 64px is width of menu bar
    let clientX = e.clientX - 64;
    let clientY = e.clientY;

    let innerWidth = window.innerWidth - 64;
    const innerHeight = window.innerHeight;

    // 300px is width of left sideBar
    if (drawerShow.leftBar) {
      clientX -= 300;
      innerWidth -= 300;
    }

    // 454px is width of right sideBar
    if (drawerShow.rightBar) {
      innerWidth -= 454;
    }

    if (clientY < innerHeight / 2) {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.TOP_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.TOP_RIGHT;
      else return MousePositionEnum.TOP_CENTER;
    } else {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_RIGHT;
      else return MousePositionEnum.BOTTOM_CENTER;
    }
  };

  return { detectMousePosition };
};

export default useCheckingPopupPosition;
