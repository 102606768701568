import { OPTION_ALL } from 'common/defines/constants';
import { DATE_STANDARD_FORMAT } from 'constants/date';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';

export const generateFilteredDataWithId = (populationData: IPopulationCount[], selectedIds: string[]) => {
  return populationData.filter((item) => {
    if (selectedIds.includes(OPTION_ALL)) {
      return true;
    } else {
      return selectedIds.includes(item.tagId.toString());
    }
  });
};

export const generateFilteredDataWithDate = (
  populationData: IPopulationCount[],
  selectedStartDate: string,
  selectedEndDate: string
) => {
  const groupedPopulationCount = groupPopulationCountWithTagId(populationData);

  return Object.values(groupedPopulationCount)
    .map((listCounts) => {
      if (!selectedStartDate && !selectedEndDate) {
        return [];
      } else if (!selectedStartDate) {
        return listCounts
          .slice(1)
          .filter((item) => moment(item.date, DATE_STANDARD_FORMAT).isSameOrBefore(moment(selectedEndDate)));
      } else if (!selectedEndDate) {
        return listCounts
          .slice(0, 1)
          .filter((item) => moment(item.date, DATE_STANDARD_FORMAT).isSameOrAfter(moment(selectedStartDate)));
      } else {
        return listCounts.filter((item) =>
          moment(item.date, DATE_STANDARD_FORMAT).isBetween(
            moment(selectedStartDate),
            moment(selectedEndDate),
            undefined,
            '[]'
          )
        );
      }
    })
    .flat();
};

export const generateFilteredFlightCommissionDates = (
  populationDate: IPopulationCount[],
  flightCommissionDates: string[]
) => {
  return populationDate.filter((item) => {
    if (flightCommissionDates.includes(OPTION_ALL)) {
      return true;
    }
    return flightCommissionDates.includes(moment(item.date).format(DATE_STANDARD_FORMAT));
  });
};

export const formatAndSortPopulationCountData = (resData: IPopulationCount[]) => {
  if (!resData || resData.length === 0) return [];
  const formattedData = resData.map((item) => ({
    ...item,
    date: new Date(moment(item.date, 'DD/MM/YYYY').format(DATE_STANDARD_FORMAT)).toISOString(),
  }));
  const sortedData = [...formattedData].sort((a, b) => {
    if (a.tagId !== b.tagId) {
      return a.tagId - b.tagId;
    }
    return moment(a.date, DATE_STANDARD_FORMAT).diff(moment(b.date, DATE_STANDARD_FORMAT));
  });

  return sortedData;
};

export const groupPopulationCountWithTagId = (populationCountData: IPopulationCount[]) => {
  return populationCountData?.reduce<Record<string, IPopulationCount[]>>((acc, cur) => {
    const key = 'tag' + cur.tagId;
    if (!acc[key]) {
      acc[key] = [cur];
    } else {
      acc[key].push(cur);
      acc[key].sort((a: IPopulationCount, b: IPopulationCount) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    }
    return acc;
  }, {});
};

export const generateGroupingDataWithPeriod = (populationCountGroupedWithTagId: Record<string, IPopulationCount[]>) => {
  return Object.values(populationCountGroupedWithTagId)
    .map((listItems) => {
      // group 2 points into each period
      return listItems
        .map((item, index) => {
          if (index < listItems.length - 1) {
            return [item, listItems[index + 1]];
          }
          return [];
        })
        .filter((item) => item && item.length);
    })
    .flat();
};

export const extractFishSpeciesName = (url: string): string => {
  const match = url.match(/([^/]+)\.(jpg|jpeg|png|gif)$/i);
  return match ? match[1] : '';
};
