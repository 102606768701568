import { ILoginFormData, IResetPasswordData } from 'common/defines/auth';
import {
  IChangeUserPassword,
  ICreateClientAdminFormData,
  ICreateClientsFormData,
  ICropTypes,
  ISocialLogin,
  IUpdateHierarchyStructureDto,
  RenderTree,
} from 'common/defines/clients';
import { PhotoIntelligenceStatusEnum } from 'constants/photoIntelligence';
import { sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest } from 'services';

export const sendGetHierarchyStructureById = (id: string) => {
  return sendGetRequest(`/hierarchy-structure/${id}`);
};

export const sendPatchHierarchyStructure = (dataStructure: IUpdateHierarchyStructureDto) => {
  return sendPatchRequest('/hierarchy-structure', { ...dataStructure });
};

export const createClientUserAdminAxios = async (data: ICreateClientAdminFormData) => {
  return sendPostRequest('/users', { ...data });
};

export const getUserListByPagination = async (
  page: number,
  perPage: number,
  keyword: string,
  clientId: string | null
) => {
  if (clientId) {
    return sendGetRequest(
      `/users/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2&clientId=${clientId}`
    );
  } else {
    return sendGetRequest(
      `/users/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2`
    );
  }
};

export const getClientUserAdminByClientIdAxios = async (id: string) => {
  return sendGetRequest(`/users/getClientAdminByClientId/${id}`);
};

export const getClientUserAdminByIdAxios = (id: string) => {
  return sendGetRequest(`/users/${id}`);
};

export const updateClientUserAdminByIdAxios = async (data: ICreateClientAdminFormData) => {
  return sendPatchRequest(`/users/${data.id}`, { ...data });
};

export const updateUserInfoNonAuth = (data: ICreateClientAdminFormData) => {
  return sendPatchRequest(`/users/currentUser`, { ...data });
};

export const updateUserInfo = (data: { sumAreaMode: boolean }) => {
  return sendPatchRequest(`/users/currentUser`, { ...data });
};

export const deleteClientUserAdminByIdAxios = async (id: string) => {
  return sendDeleteRequest(`/users/${id}`);
};

export const createLevelCropType = (parent: string | null, clientId: string, cropType: ICropTypes, name: string) => {
  return sendPostRequest(`/hierarchy-structure`, {
    parent,
    clientId,
    cropType,
    name,
  });
};

export const deleteLevelCropTypeById = (id: string) => {
  return sendDeleteRequest(`/hierarchy-structure/${id}`);
};

export const getClientById = async (clientId: string) => {
  const response = await sendGetRequest(`/clients/${clientId}`);

  if (!response?.data) {
    throw new Error('Failed to fetch!');
  }

  return response.data;
};

export const getClientByIdNew = async (clientId: string) => {
  const response = await sendGetRequest(`/clients/client-information/${clientId}`);

  if (!response?.data) {
    throw new Error('Failed to fetch!');
  }

  return response.data;
};

export const getClientSettingsById = async (clientId: string) => {
  const response = await sendGetRequest(`/client-setting/${clientId}/`);

  if (!response?.data) {
    throw new Error('Failed to fetch!');
  }

  return response.data;
};

export const updateClientById = async (clientId: string, data: any) => {
  return sendPatchRequest(`/clients/${clientId}`, { ...data });
};
export const updateClientSettingsById = async (clientId: string, data: any) => {
  return sendPatchRequest(`/client-setting/${clientId}`, { ...data });
};
export const generateClientKey = async (clientSettingId: string) => {
  return sendPatchRequest(`/client-setting/generateClientKey/${clientSettingId}`);
};

export const getLevelListByCropType = async (
  clientId?: string | null,
  cropType?: ICropTypes | null,
  sumAreaMode: boolean = true
) => {
  const response = await sendGetRequest(`/hierarchy-structure/treeStructure/${clientId}/${cropType}`, { sumAreaMode });

  if (!response?.data) {
    throw new Error('Failed to fetch!');
  }

  return response.data as RenderTree[];
};

export const getLevelCountByCropType = async (clientId?: string | null, cropType?: string | null) => {
  const response = await sendGetRequest(`/hierarchy-structure/levelCountByCropType/${clientId}/${cropType}`);

  if (!response?.data) {
    throw new Error('Failed to fetch!');
  }

  return response.data;
};

export const getClientList = async () => {
  return sendGetRequest(`/clients`);
};

export const getClientInSummaryList = async () => {
  return sendGetRequest(`/clients/in-summary`);
};

export const createUiDisplay = async (data: any) => {
  return sendPostRequest(`/ui-display`, { ...data });
};

export const updateUIDisplay = async (id: string, data: any) => {
  return sendPatchRequest(`/ui-display/${id}`, { ...data });
};

export const getUIDisplay = async (clientId: string, userId: string) => {
  return sendGetRequest(`/ui-display/${clientId}/${userId}`);
};

export const getClientListByPagination = async (page: number, perPage: number, keyword: string | null) => {
  return sendGetRequest(
    `/clients/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2`
  );
};

export const getClientSummary = async ({ ids, sumAreaMode = true }: { ids: any; sumAreaMode: boolean }) => {
  return sendGetRequest(`/clients/client-summary`, { ids, sumAreaMode });
};

export const queryMe = () => {
  return sendGetRequest('/auth/me');
};

export const deleteClientByIdReactQuery = (id: string) => {
  return sendDeleteRequest(`/clients/${id}`);
};

export const createClientReactQuery = (data: ICreateClientsFormData) => {
  return sendPostRequest(`/clients/`, data);
};

export const sendGetAllLocation = (clientId: string | null, cropType?: ICropTypes | null, parent?: string | null) => {
  return sendGetRequest(`/hierarchy-structure/getAllLocationByCropType`, { parent: parent, clientId, cropType });
};

export const exportKMLFile = (id: string | undefined, fileType: string) => {
  return sendGetRequest(`hierarchy-structure/exportKMLFile/${id}`, { responseType: 'arraybuffer', fileType });
};

export const socialLogin = async (data: ISocialLogin) => {
  const res = await sendPostRequest('/social-auth/loginSocial', { ...data });
  if (res.status === 200) {
    localStorage.setItem('token', res.data.accessToken);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('sessionLogin', res.data.sessionLogin);
    localStorage.setItem('account', JSON.stringify(res.data.user));
    return res.data;
  } else {
    throw new Error('Failed to fetch!');
  }
};

export const sendLoginRequest = async (data: ILoginFormData) => {
  const res = await sendPostRequest('/auth/login', {
    ...data,
  });
  if (res.status === 200) {
    localStorage.setItem('token', res.data.accessToken);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('sessionLogin', res.data.sessionLogin);
    localStorage.setItem('account', JSON.stringify(res.data.user));
    return res.data;
  } else {
    throw new Error('Failed to fetch!');
  }
};

export const logoutUser = async (sessionId: string) => {
  return sendPatchRequest(`/session-login/${sessionId}`);
};

export const resetUserPasswordById = (id: string) => {
  return sendPatchRequest(`/users/resetUserPassword/${id}`);
};

export const getUserDetails = async (id: string | undefined) => {
  return sendGetRequest(`/users/${id}`);
};

export const getRoleData = async (Id: string | undefined) => {
  return sendGetRequest(`/role/${Id}`);
};

export const getUserLoginSessionByPagination = async (page: number, perPage: number, keyword: string) => {
  return sendGetRequest(
    `/session-login/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2`
  );
};

export const getUserLoginSessionByPaginationClient = async (
  page: number,
  perPage: number,
  keyword: string,
  clientId: string
) => {
  return sendGetRequest(
    `/session-login/pagination?keyword=${keyword}&clientId=${clientId}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2`
  );
};

export const getFilterMultiChart = async (data: { startDate: string; endDate: string }) => {
  return sendGetRequest(`/session-login/multipleChart?startDate=${data.startDate}&endDate=${data.endDate}`);
};

export const getFilterMultiChartClient = async (data: { startDate: string; endDate: string; clientId: string }) => {
  return sendGetRequest(
    `/session-login/multipleChart?startDate=${data.startDate}&endDate=${data.endDate}&clientId=${data.clientId}`
  );
};

export const createRole = (data: any) => {
  return sendPostRequest(`/role`, {
    ...data,
  });
};

export const getRoleListByPagination = async (page: number, perPage: number, keyword: string) => {
  return sendGetRequest(
    `/role/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2`
  );
};

export const deleteRoleById = async (id: string | undefined) => {
  return sendDeleteRequest(`/role/${id}`);
};

export const updateRoleById = async (data: any) => {
  return sendPatchRequest(`/role/${data.id}`, data);
};

export const getAllRole = async () => {
  return sendGetRequest(`/role`);
};

export const resetPassword = async (email: string) => {
  return sendPostRequest('/auth/forgot-password', email);
};

export const changePassword = async (data: IResetPasswordData, token: string) => {
  return sendPatchRequest(`/auth/change-password/${token}`, data);
};

export const sendVerifyEmail = async (data: any) => {
  return sendPostRequest('/auth/send-verify-email', { ...data });
};

export const verifyEmail = async (token: string) => {
  return sendPatchRequest(`/auth/verify-email/${token}`);
};

export const sendGetLocationDetails = (id: string) => {
  return sendGetRequest(`/hierarchy-structure/${id}`);
};

export const sendGetLocationDetailsInformation = (levelId: string) => {
  return sendGetRequest(`/hierarchy-structure/getDetailsAggregate/${levelId}`);
};

export const sendGetDataAnalysisByLevelIdDate = (id: string, date: string) => {
  return sendGetRequest(`/data-analytics/getAnalysisByDate/${id}/${date}`);
};

export const getUserDataById = (id: string) => {
  return sendGetRequest(`/session-login/${id}`);
};

export const changeUserPassword = (data: IChangeUserPassword) => {
  return sendPatchRequest(`/auth/updateCurrentUserPassword`, data);
};

export const updateSortTree = (data: any) => {
  return sendPatchRequest(`/hierarchy-structure/updatePosition`, data);
};

export const updateSortTreeAnalyticConfig = (data: any) => {
  return sendPatchRequest(`/data-analytics/updatePosition`, data);
};

export const getListUserShare = (id: any) => {
  return sendGetRequest(`/users/findUserWithSameRole/${id}`);
};

export const sendEmailSharing = (data: any) => {
  return sendPostRequest(`/issue/share`, data);
};

export const exportExcelInformationTab = ({
  levelId,
  clientId,
  cropType,
}: {
  levelId: string | null;
  clientId: string;
  cropType?: string;
}) => {
  return sendGetRequest(`/excel/export/information?levelId=${levelId}`, { clientId, cropType });
};

export const exportExcelAnalyticsTab = ({
  levelId,
  clientId,
  cropType,
}: {
  levelId: string | null;
  clientId: string;
  cropType?: string;
}) => {
  return sendGetRequest(`/excel/export/analytics-tab?levelId=${levelId}`, { clientId, cropType });
};

export const exportExcelLastLevelAnalytics = (analyticsId: string, intervalDefault?: boolean, size?: number) => {
  return sendGetRequest(`/excel/export/analytics/${analyticsId}`, { intervalDefault, size });
};

export const getStandCountBoundary = (analyticId: string) => {
  return sendGetRequest(`/stand-count-analysis/getBoundary/${analyticId}`);
};

export const getPhotoIntelligenceAnalysis = async (analyticId: string) => {
  const res = await sendGetRequest(`/photo-intelligence-analysis/getAll/${analyticId}`);
  return res?.data;
};

export const getPhotoIntelligenceAllLocation = async (levelId: string) => {
  const response = await sendGetRequest(`/photo-intelligence-analysis/getFromMLevel/${levelId}`);
  return response?.data;
};

export const updatePhotoIntelligenceStatus = async (data: { _id: string; status: PhotoIntelligenceStatusEnum }) => {
  const { _id, status } = data;
  return sendPostRequest('/photo-intelligence-analysis/changeStatus', { photoId: _id, status });
};

export const editCommentPhotoIntelligence = async (data: { _id: string; content: string; media: string }) => {
  const { _id, content, media } = data;
  return sendPatchRequest(`/photo-intelligence-analysis/updateComment/${_id}`, { content, media });
};

export const deleteCommentPhotoIntelligence = (id: string) => {
  return sendDeleteRequest(`/photo-intelligence-analysis/deleteComment/${id}`);
};

export const createPhotoIntelligenceComment = async (data: {
  photoIntelligenceId: string;
  content: string;
  media: string;
}) => {
  const { photoIntelligenceId, content, media } = data;
  return sendPostRequest('/photo-intelligence-analysis/createComment', {
    photoIntelligenceId: photoIntelligenceId,
    content,
    media,
  });
};

export const getPhotoIntelligenceComment = async (data: {
  page: number;
  perPage: number;
  photoIntelligenceId: string;
}) => {
  const sortDate = 'desc';
  const paginationVersion = 2;
  const { page, perPage, photoIntelligenceId } = data;
  return sendGetRequest('/photo-intelligence-analysis/commentPagination/', {
    page,
    perPage,
    sortDate,
    paginationVersion,
    photoIntelligenceId,
  });
};

export const getPhotoIntelligenceAssociatedKps = async (data: { kpId: string; levelId: string }) => {
  const { kpId, levelId } = data;
  const response = await sendGetRequest(`/photo-intelligence-analysis/findAssociatedKps/${kpId}/${levelId}`);
  return response.data;
};

export const getPhotoIntelligenceKpDetail = async (data: { kpId: string }) => {
  const { kpId } = data;
  const response = await sendGetRequest(`/photo-intelligence-analysis/getDetail/${kpId}`);
  return response.data;
};

export const updateRasterPosition = async (data: { id: string; positionNumber: number }[]) => {
  return await sendPatchRequest(`/data-analytics/updateRasterPosition`, { rasterPositionNumber: data });
};

export const changeLayerTypeName = async (data: { name: string; rasterId: string }) => {
  return await sendPatchRequest(`/data-analytics/changeRasterName`, data);
};
