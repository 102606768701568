import { Box, Typography } from '@mui/material';
import { generateComparedTime } from 'common/utils/time';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import useGetDataPopulationCount from 'hooks/fish-migration/useGetDataPopulationCount';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';
import AquaticSpeciesTrackingTable from './AquaticSpeciesTrackingTable';
import IdSelect from './IdSelect';
import TimeFrameSelect from './TimeFrameSelect';

const AquaticSpeciesTrackingAnalytics = () => {
  const { t } = useTranslation();
  const classes = useLayerSelectCardStyle();

  const {
    aquaticSpeciesTracking: { selectedTimeFrame },
  } = useAppSelector(fishMigrationSelector);

  const { populationCountData } = useGetDataPopulationCount();

  if (!populationCountData) return null;

  const comparedTime = generateComparedTime(selectedTimeFrame);

  const filteredDateRange = populationCountData.filter((item) => {
    return moment(item.date).isAfter(comparedTime);
  });

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        height: 'fit-content',
        width: '100%',
        borderRadius: '8px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        padding: '20px',
      }}>
      <Typography className={classes.buttonTextStyle} sx={{ fontSize: '16px !important', fontWeight: 600 }}>
        {t('trans.filter')}
      </Typography>
      <TimeFrameSelect />
      {filteredDateRange && (
        <>
          <IdSelect filteredDateRange={filteredDateRange} />
          <Box sx={{ marginX: '-14px' }}>
            <AquaticSpeciesTrackingTable filteredDateRange={filteredDateRange} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AquaticSpeciesTrackingAnalytics;
