import { LayerTypeEnum } from './constants';

export interface AnalyticConfigurations {
  _id: string;
  analysisArea: string;
  typeOfAnalytic: string;
  createdAt: string;
  status: boolean;
  updatedAt: string;
}

export interface AnalyticDTO {
  _id: string;
  settingId: string;
  levelId: string;
  zipFile: any;
  jsonFile: any;
  setting: {
    typeOfAnalytic: string;
    analysisArea: string;
    _id: string;
  };
}

export interface RasterLayerDTO {
  _id: string;
  analysisId: {
    _id: string;
    name: string;
  };
  fileType: string;
  layerType: LayerTypeEnum | string;
  isCommon?: boolean;
  orderNumber: number;
}

export interface IAnalytic {
  analysisId: string;
  fileType: string;
  geoJsonType: null;
  layerType: LayerTypeEnum;
  name: string;
  path: string;
  size: number;
}

export interface ISoilMoistureContentAnalysis {
  analysisId: string;
  area: number;
  areaPercentage: number;
  createdAt: string;
  geometry: {
    type: string;
    coordinates: any[];
  };
  soilMoistureContentId: number;
  updatedAt: string;
}

export interface IFissureAnalysis {
  analysisId: string;
  createdAt: string;
  geometry: {
    type: string;
    coordinates: any[];
  };
  length: number;
  updatedAt: string;
}

export interface IStandPointData {
  _id: string;
  latY: number;
  longX: number;
  treeId: string;
  area?: number;
  color?: string;
  diaLength?: number;
  cirLength?: number;
  initialDiaLength?: number;
}

export enum StandPointKeyEnum {
  STAND_COUNT = 1,
  CIRCUMFERENCE = 2,
  CROWN_AREA = 3,
}

export interface ISwitchItem {
  key: StandPointKeyEnum;
  label: string;
  visible: boolean;
  color: string;
}

export type ISwitchItemPayload = Pick<ISwitchItem, 'key'> & Partial<ISwitchItem>;
