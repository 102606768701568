import { listFishMigrationAnalytics, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { useAquaticSpeciesTrackingAnalytics } from './analytics/useAquaticSpeciesTrackingAnalytics';
import { useChlorophyllAnalytics } from './analytics/useChlorophyllAnalytics';
import { useChlorophyllPaddyAnalytics } from './analytics/useChlorophyllPaddyAnalytics';
import { useCircumferenceAnalytics } from './analytics/useCircumferenceAnalytics';
import { useCo2SequestrationAnalytics } from './analytics/useCo2SequestrationAnalytics';
import { useCropCoverageAnalytics } from './analytics/useCropCoverageAnalytics';
import { useCrownAreaAnalytics } from './analytics/useCrownAreaAnalytics';
import { useCustomFieldAnalytics } from './analytics/useCustomFieldAnalytics';
import { useElevationFieldAnalytics } from './analytics/useElevationFieldAnalytics';
import { useFissureAnalytics } from './analytics/useFissureAnalytics';
import { useGapAnalytics } from './analytics/useGapAnalytics';
import { useLandUseAnalytics } from './analytics/useLandUseAnalytics';
import { useLeafPhenologyFieldAnalytics } from './analytics/useLeafPhenologyFieldAnalytics';
import usePhotoIntelligenceAnalytics from './analytics/usePhotoIntelligenceAnalytics';
import { usePlantHealthAnalytics } from './analytics/usePlantHealthAnalytics';
import { usePlantHealthOilPailmAnalytics } from './analytics/usePlantHealthOilPalm';
import { usePlantStressAnalytics } from './analytics/usePlantStressAnalytics';
import { usePopulationCountAnalytics } from './analytics/usePopulationCountAnalytics';
import { useSlopeFieldAnalytics } from './analytics/useSlopeFieldAnalytics';
import { useSoilErosionFieldAnalytics } from './analytics/useSoilErosionFieldAnalytics';
import { useSoilMoistureContent } from './analytics/useSoilMoistureContent';
import { useSoilWaterContentFieldAnalytics } from './analytics/useSoilWaterContentFieldAnalytics';
import { useStandCountAnalytics } from './analytics/useStandCountAnalytics';
import { useStressFieldAnalytics } from './analytics/useStressFieldAnalytics';
import { useTillerDensityAnalytics } from './analytics/useTillerDensityAnalytics';
import { useTreeHeightAnalytics } from './analytics/useTreeHeightAnalytics';
import { useTreeTiltFallenAnalytics } from './analytics/useTreeTiltFallenAnalytics';
import { useVacantAreaAnalytics } from './analytics/useVacantAreaAnalytics';
import { useVegetationEncroachmentAnalytics } from './analytics/useVegetationEncroachmentAnalytics';
import { useVigorAnalytics } from './analytics/useVigorAnalytics';
import { useVigorPaddyAnalytics } from './analytics/useVigorPaddyAnalytics';
import { useWatershedBasinAnalytics } from './analytics/useWatershedBasinAnalytics';
import { useWaterUptakeAnalytics } from './analytics/useWaterUptakeAnalytics';
import { useWaterUptakeFieldAnalytics } from './analytics/useWaterUptakeFieldAnalytics';
import { useWeedInvasionAnalytics } from './analytics/useWeedInvasionAnalytics';

export const useViewAnalyticLayer = ({ mapRef, isMap3D }: any) => {
  const { analyticName } = useAppSelector(rightBarSelector);

  //Layer analytics
  const { layerLandUse } = useLandUseAnalytics();
  const { layerCropCoverage } = useCropCoverageAnalytics(mapRef);
  const { layerWeedInvasion } = useWeedInvasionAnalytics(mapRef);
  const { layerVacantArea } = useVacantAreaAnalytics(mapRef);
  const { layerStandCount } = useStandCountAnalytics({ mapRef, isMap3D });
  const { layerPlantHealth } = usePlantHealthAnalytics(mapRef);
  const { layerTillerDensity } = useTillerDensityAnalytics(mapRef);
  const { layerChlorophyllPaddy } = useChlorophyllPaddyAnalytics(mapRef);
  const { layerCircumference } = useCircumferenceAnalytics({ mapRef, isMap3D });
  const { layerTreeHeight } = useTreeHeightAnalytics(mapRef);
  const { layerCo2Sequestration } = useCo2SequestrationAnalytics(mapRef);
  const { layerVigor } = useVigorAnalytics({ mapRef, isMap3D });
  const { layerVigorPaddy } = useVigorPaddyAnalytics({ mapRef, isMap3D });
  const { layerPlantStress } = usePlantStressAnalytics({ mapRef, isMap3D });
  const { layerCrownArea } = useCrownAreaAnalytics({ mapRef, isMap3D });
  const { layerGap } = useGapAnalytics({ mapRef, isMap3D });
  const { layerWaterUptake } = useWaterUptakeAnalytics({ mapRef, isMap3D });
  const { layerChlorophyll } = useChlorophyllAnalytics({ mapRef, isMap3D });
  const { layerPlantHealthOilPailm } = usePlantHealthOilPailmAnalytics({ mapRef, isMap3D });
  const { layerStressField } = useStressFieldAnalytics({ mapRef, isMap3D });
  const { layerWaterUptakeField } = useWaterUptakeFieldAnalytics({ mapRef, isMap3D });
  const { layerSoilWaterContentField } = useSoilWaterContentFieldAnalytics({ mapRef, isMap3D });
  const { layerSoilErosionField } = useSoilErosionFieldAnalytics({ mapRef, isMap3D });
  const { layerLeafPhenologyField } = useLeafPhenologyFieldAnalytics({ mapRef, isMap3D });
  const { layerElevationField } = useElevationFieldAnalytics({ mapRef, isMap3D });
  const { layerSlopeField } = useSlopeFieldAnalytics({ mapRef, isMap3D });
  const { layerCustomField } = useCustomFieldAnalytics({ mapRef, isMap3D });
  const { layerSoilMoistureContent } = useSoilMoistureContent();
  const { layerFissure } = useFissureAnalytics();
  const { layerWatershedBasin } = useWatershedBasinAnalytics();
  const { layerTreeTiltFallen } = useTreeTiltFallenAnalytics(mapRef);
  const { layerVegetationEncroachment } = useVegetationEncroachmentAnalytics({ mapRef, isMap3D });
  const { layerPhotoIntelligence } = usePhotoIntelligenceAnalytics();
  const { layerPopulationCount } = usePopulationCountAnalytics({ mapRef });
  const { layerAquaticSpeciesTracking } = useAquaticSpeciesTrackingAnalytics();

  const polygonAnalytics = useMemo(() => {
    if (layerLandUse) {
      return layerLandUse;
    }
    if (layerCropCoverage) {
      return layerCropCoverage;
    }
    if (layerWeedInvasion) {
      return layerWeedInvasion;
    }
    if (layerStandCount) {
      return layerStandCount;
    }
    if (layerCircumference) {
      return layerCircumference;
    }
    if (layerTreeHeight) {
      return layerTreeHeight;
    }
    if (layerCo2Sequestration) {
      return layerCo2Sequestration;
    }
    if (layerVigor) {
      return layerVigor;
    }
    if (layerPlantStress) {
      return layerPlantStress;
    }
    if (layerCrownArea) {
      return layerCrownArea;
    }
    if (layerGap) {
      return layerGap;
    }
    if (layerWaterUptake) {
      return layerWaterUptake;
    }
    if (layerChlorophyll) {
      return layerChlorophyll;
    }
    if (layerPlantHealth) {
      return layerPlantHealth;
    }
    if (layerVacantArea) {
      return layerVacantArea;
    }
    if (layerTillerDensity) {
      return layerTillerDensity;
    }
    if (layerChlorophyllPaddy) {
      return layerChlorophyllPaddy;
    }
    if (layerStressField) {
      return layerStressField;
    }
    if (layerWaterUptakeField) {
      return layerWaterUptakeField;
    }
    if (layerSoilWaterContentField) {
      return layerSoilWaterContentField;
    }
    if (layerSoilErosionField) {
      return layerSoilErosionField;
    }
    if (layerLeafPhenologyField) {
      return layerLeafPhenologyField;
    }
    if (layerElevationField) {
      return layerElevationField;
    }
    if (layerSlopeField) {
      return layerSlopeField;
    }
    if (layerCustomField) {
      return layerCustomField;
    }
    if (layerVigorPaddy) {
      return layerVigorPaddy;
    }
    if (layerPlantHealthOilPailm) {
      return layerPlantHealthOilPailm;
    }
    if (layerSoilMoistureContent) {
      return layerSoilMoistureContent;
    }
    if (layerFissure) {
      return layerFissure;
    }
    if (layerWatershedBasin) {
      return layerWatershedBasin;
    }
    if (layerTreeTiltFallen) {
      return layerTreeTiltFallen;
    }
    if (layerVegetationEncroachment) {
      return layerVegetationEncroachment;
    }
    if (layerPhotoIntelligence) {
      return layerPhotoIntelligence;
    }

    // Fish Migration Analytics
    if (listFishMigrationAnalytics.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW)) {
      if (analyticName === TYPE_ANALYTICS_MAP_VIEW.POPULATION_COUNT && layerPopulationCount) {
        return layerPopulationCount;
      }
      if (analyticName === TYPE_ANALYTICS_MAP_VIEW.AQUATIC_SPECIES_TRACKING && layerAquaticSpeciesTracking) {
        return layerAquaticSpeciesTracking;
      }
    }
    return <></>;
  }, [
    layerLandUse,
    layerCropCoverage,
    layerWeedInvasion,
    layerStandCount,
    layerCircumference,
    layerTreeHeight,
    layerVigor,
    layerPlantStress,
    layerCrownArea,
    layerGap,
    layerWaterUptake,
    layerChlorophyll,
    layerPlantHealth,
    layerVacantArea,
    layerTillerDensity,
    layerChlorophyllPaddy,
    layerStressField,
    layerWaterUptakeField,
    layerSoilWaterContentField,
    layerSoilErosionField,
    layerLeafPhenologyField,
    layerElevationField,
    layerSlopeField,
    layerCustomField,
    layerSoilMoistureContent,
    layerFissure,
    layerVigorPaddy,
    layerPlantHealthOilPailm,
    layerWatershedBasin,
    layerTreeTiltFallen,
    layerVegetationEncroachment,
    layerCo2Sequestration,
    layerPhotoIntelligence,
    layerPopulationCount,
    layerAquaticSpeciesTracking,
    analyticName,
  ]);

  return { polygonAnalytics };
};
