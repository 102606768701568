import PublicIcon from '@mui/icons-material/Public';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Box,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { ITableHeaders, TSortOrder } from 'common/defines/constants';
import { extractFishSpeciesName } from 'common/utils/fish-migration';
import { generateKmlForPoint } from 'common/utils/generate-kml';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { changeFocusedTagState } from 'store/slices/map-view/fishMigrationAnalytics';
import { CustomTableBodyCell, CustomTableHeaderCell } from '../components/CustomTableCell';

enum TableKeyEnum {
  ID = 'ID',
  SPECIES = 'SPECIES',
  SIGNAL_STRENGTH = 'SIGNAL_STRENGTH',
  DATE = 'DATE',
}

const initialTableHeaders = [
  { key: TableKeyEnum.ID, label: 'trans.id', sortOrder: null },
  { key: TableKeyEnum.SPECIES, label: 'trans.species', sortOrder: null },
  { key: TableKeyEnum.SIGNAL_STRENGTH, label: 'trans.signal_strength', sortOrder: null },
  { key: TableKeyEnum.DATE, label: 'trans.date', sortOrder: null },
];

interface PopulationTableProps {
  populationTableData: IPopulationCount[];
}

const PopulationTable: FC<PopulationTableProps> = ({ populationTableData }) => {
  const [tableHeaders, setTableHeaders] = useState<ITableHeaders<TableKeyEnum>[]>(initialTableHeaders);
  const [sortInfo, setSortInfo] = useState<{ key: TableKeyEnum; sortOrder: TSortOrder }>({
    key: TableKeyEnum.ID,
    sortOrder: 'ASC',
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const convertedPopulationTableData = populationTableData.map((item) => ({
    ...item,
    species: extractFishSpeciesName(item.image),
  }));

  const handleClickTagId = (row: IPopulationCount) => {
    dispatch(
      changeFocusedTagState({
        info: {
          id: row._id,
          long: row.lon,
          lat: row.lat,
        },
        isFlyDone: false,
      })
    );
  };

  const handleClickIcon = (row: IPopulationCount) => {
    const { lon, lat, date, tagId, image, power } = row;
    const formatDate = moment(date).format(DATE_VISUALIZE_FORMAT);
    const name = `population_count_${formatDate}`;
    const kmlContent = generateKmlForPoint({
      name,
      properties: [
        {
          geometries: [{ longitude: lon, latitude: lat }],
          name: `population_count_${tagId}`,
          tagId: tagId,
          date: date.toString(),
          species: extractFishSpeciesName(image),
          power,
        },
      ],
    });
    const blob = new Blob([kmlContent], { type: 'application/vnd.google-earth.kml+xml' });
    saveAs(blob, `population_count_${formatDate}.kml`);
  };

  const renderCoordinates = (row: IPopulationCount) => {
    return (
      <Tooltip
        title={
          <>
            <Typography>
              {t('trans.longitude')}: {row.lon}
            </Typography>
            <Typography>
              {t('trans.latitude')}: {row.lat}
            </Typography>
          </>
        }
        placement="top-end">
        <PublicIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => handleClickIcon(row)} />
      </Tooltip>
    );
  };

  const renderSortIcon = (sortOrder: TSortOrder) => {
    switch (sortOrder) {
      case 'ASC':
        return DescIcon;
      case 'DESC':
        return AscIcon;
      default:
        return SwapVertIcon;
    }
  };

  const handleSortTable = (key: TableKeyEnum, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newTableHeaders = tableHeaders.map((header) =>
      header.key === key ? { ...header, sortOrder: newSortOrder } : { ...header, sortOrder: null }
    );
    setTableHeaders(newTableHeaders);
  };

  const sortFunction = (first: IPopulationCount, second: IPopulationCount) => {
    const { key, sortOrder } = sortInfo;
    if (key === TableKeyEnum.ID) {
      return sortOrder === 'ASC' ? first.tagId - second.tagId : second.tagId - first.tagId;
    } else if (key === TableKeyEnum.SPECIES) {
      return sortOrder === 'ASC'
        ? first.species.localeCompare(second.species)
        : second.species.localeCompare(first.species);
    } else if (key === TableKeyEnum.SIGNAL_STRENGTH) {
      return sortOrder === 'ASC' ? first.power - second.power : second.power - first.power;
    } else if (key === TableKeyEnum.DATE) {
      return sortOrder === 'ASC' ? moment(first.date).diff(second.date) : moment(second.date).diff(first.date);
    }

    return 0;
  };

  const sortedTableData = [...convertedPopulationTableData].sort((a, b) => sortFunction(a, b));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((item) => (
              <CustomTableHeaderCell key={item.key} sx={{ width: '120px' }}>
                <Box display="flex" alignItems="center">
                  {t(item.label)}
                  <SvgIcon
                    component={renderSortIcon(item.sortOrder)}
                    inheritViewBox
                    sx={{ fontSize: '14px', cursor: 'pointer', ml: '4px' }}
                    onClick={() => handleSortTable(item.key, item.sortOrder)}
                  />
                </Box>
              </CustomTableHeaderCell>
            ))}
            <CustomTableHeaderCell>Kml</CustomTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTableData.map((row, index) => {
            const { tagId, power, image, date } = row;

            const species = extractFishSpeciesName(image);

            return (
              <TableRow key={index}>
                <CustomTableBodyCell sx={{ cursor: 'pointer' }} onClick={() => handleClickTagId(row)}>
                  {tagId}
                </CustomTableBodyCell>
                <CustomTableBodyCell>{species}</CustomTableBodyCell>
                <CustomTableBodyCell>{power}</CustomTableBodyCell>
                <CustomTableBodyCell sx={{ minWidth: '100px' }}>
                  {moment(date).format(DATE_VISUALIZE_FORMAT)}
                </CustomTableBodyCell>
                <CustomTableBodyCell>{renderCoordinates(row)}</CustomTableBodyCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PopulationTable;
