import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { PeriodEnum } from 'common/defines/clients';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSelectedTimeFrame, fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';
import CommonCollapse from './components/CommonCollapse';

const TimeFrameSelect = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    aquaticSpeciesTracking: { selectedTimeFrame },
  } = useAppSelector(fishMigrationSelector);

  const listTimeFrame = [
    { key: 1, value: PeriodEnum.ONE_MONTH, label: t('trans.1_months') },
    { key: 2, value: PeriodEnum.SIX_MONTHS, label: t('trans.6_months') },
    { key: 3, value: PeriodEnum.TWELVE_MONTHS, label: t('trans.12_months') },
    { key: 4, value: PeriodEnum.MAX, label: t('trans.max') },
  ];

  const handleSelectCheckbox = (e: ChangeEvent<HTMLInputElement>, value: PeriodEnum) => {
    const checked = e.target.checked;
    if (checked) {
      dispatch(changeSelectedTimeFrame(value));
    }
  };

  return (
    <Box sx={{ mt: '8px' }}>
      <CommonCollapse label={t('trans.date_range')}>
        {listTimeFrame.map((item) => (
          <Stack>
            <FormControlLabel
              key={item.key}
              sx={{ height: '28px' }}
              control={
                <Checkbox
                  checked={item.value === selectedTimeFrame}
                  onChange={(e) => handleSelectCheckbox(e, item.value)}
                />
              }
              label={item.label}
            />
          </Stack>
        ))}
      </CommonCollapse>
    </Box>
  );
};

export default TimeFrameSelect;
