import PlaceIcon from '@mui/icons-material/Place';
import { Box } from '@mui/material';
import { distance as turfDistance, point as turfPoint } from '@turf/turf';
import { DIFFERENT_COLOR, MousePositionEnum } from 'common/defines/constants';
import { generateComparedTime } from 'common/utils/time';
import PopupParagraph from 'components/Common/PopupParagraph';
import StyledPopup from 'components/MapView/StyledPopup';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import useCheckingPopupPosition from 'hooks/common/useCheckingPopupPosition';
import useGetDataPopulationCount from 'hooks/fish-migration/useGetDataPopulationCount';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Marker, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';

const fixedSize = {
  height: 200,
  width: 300,
};

export const useAquaticSpeciesTrackingAnalytics = () => {
  const [hoveredMarkerDetail, setHoveredMarkerDetail] = useState<IPopulationCount>();

  const mousePosition = useRef<MousePositionEnum>(MousePositionEnum.TOP_CENTER);

  const { t } = useTranslation();
  const {
    aquaticSpeciesTracking: { listSelectedIds, selectedTimeFrame },
  } = useAppSelector(fishMigrationSelector);

  const comparedTime = generateComparedTime(selectedTimeFrame);

  const { populationCountData } = useGetDataPopulationCount();
  const { detectMousePosition } = useCheckingPopupPosition({});

  const listPopupMarker = (item: IPopulationCount) => {
    return [
      { key: t('trans.id'), value: item.tagId },
      { key: t('trans.species'), value: item.species },
      { key: t('trans.date'), value: moment(item.date).format(DATE_VISUALIZE_FORMAT) },
    ];
  };

  const setTagIds = [...new Set(populationCountData?.map((item) => item.tagId))];

  const getColor = (id: number) => {
    return DIFFERENT_COLOR[setTagIds.indexOf(id)];
  };

  const reducedDrawingLinesObj = populationCountData?.reduce<Record<string, IPopulationCount[]>>((acc, cur) => {
    const key = 'tag' + cur.tagId;
    if (!acc[key]) {
      acc[key] = [cur];
    } else {
      acc[key].push(cur);
      acc[key].sort((a: IPopulationCount, b: IPopulationCount) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    }
    return acc;
  }, {});

  const listFeatures = Object.values(populationCountData?.length ? reducedDrawingLinesObj : {})
    // filter selected Id
    ?.filter((listItems) => {
      const [first] = listItems;
      return listSelectedIds.includes(first.tagId);
    })
    // filter date range
    ?.filter((listItems) => {
      const first = listItems[0];
      const last = listItems[listItems.length - 1];
      const firstDate = moment(first.date);
      const lastDate = moment(last.date);

      if (firstDate.isAfter(comparedTime) && lastDate.isAfter(comparedTime)) {
        return true;
      }
      return false;
    })
    // group into each small line string include two points
    ?.map((listItems) => {
      return listItems
        .map((item, index) => {
          if (index < listItems.length - 1) {
            return [item, listItems[index + 1]];
          }
          return [];
        })
        .filter((item) => item && item.length);
    })
    .flat();

  const geoJsonData: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: listFeatures?.map((listItems) => {
      const first = listItems[0];
      const last = listItems[listItems.length - 1];

      const firstCoordinate = [first.lon, first.lat];
      const lastCoordinate = [last.lon, last.lat];

      const from = turfPoint(firstCoordinate);
      const to = turfPoint(lastCoordinate);

      const distance = turfDistance(from, to, { units: 'meters' });

      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [firstCoordinate, lastCoordinate],
        },
        properties: {
          color: getColor(first.tagId),
          distance,
        },
      };
    }) as GeoJSON.Feature[],
  };

  const renderDrawingLines = (
    <Source id="line-source" type="geojson" data={geoJsonData}>
      <Layer
        id="population-tracking-lines"
        type="line"
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        paint={{
          'line-color': ['get', 'color'],
          'line-width': 3,
        }}
      />
      <Layer
        id="arrow-layer"
        type="symbol"
        layout={{
          'symbol-placement': 'line',
          'symbol-spacing': 100, // Distance between arrows in pixels
          'text-field': '▶', // Unicode arrow character
          'text-size': 30,
          'text-keep-upright': false,
          'text-rotation-alignment': 'map',
          'text-allow-overlap': true,
          'text-ignore-placement': true,
          'text-pitch-alignment': 'viewport',
        }}
        paint={{
          'text-color': ['get', 'color'],
        }}
      />
    </Source>
  );

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    mousePosition.current = detectMousePosition(e);
  };

  const calculateOffsetYWithPosition = () => {
    if (mousePosition.current.includes('top')) return 10;
    else return -30;
  };

  const renderPinPointList = populationCountData
    ?.filter((item) => {
      return listSelectedIds.includes(item.tagId);
    })
    ?.filter((item) => {
      if (moment(item.date).isAfter(comparedTime)) return true;
      return false;
    })
    ?.map((item) => {
      return (
        <Marker longitude={item.lon} latitude={item.lat} anchor="bottom" key={item._id}>
          <Box
            onMouseMove={handleMouseMove}
            position="relative"
            sx={{
              height: '24px',
              width: '24px',
            }}>
            <PlaceIcon
              fontSize="large"
              sx={{ color: getColor(item.tagId) }}
              onMouseEnter={() => setHoveredMarkerDetail(item)}
              onMouseLeave={() => setHoveredMarkerDetail(undefined)}
            />
            {item._id === hoveredMarkerDetail?._id && (
              <StyledPopup
                longitude={item.lon}
                latitude={item.lat}
                anchor={mousePosition.current}
                closeButton={false}
                closeOnClick={false}
                style={{ ...fixedSize, maxWidth: 'unset' }}
                offset={[0, calculateOffsetYWithPosition()]}>
                <Box>
                  <Box
                    sx={{
                      border: 0,
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      backgroundColor: (theme) => theme.palette.background.default,
                    }}>
                    <Box sx={{ p: '8px' }}>
                      <Box sx={{}}>
                        <img
                          src={item.image}
                          alt="fish"
                          height={'100%'}
                          width={'100%'}
                          style={{ borderRadius: '8px' }}
                        />
                      </Box>
                      {listPopupMarker(item).map((row, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <PopupParagraph value={row.key} variant="label" />
                          <PopupParagraph value={row.value} variant="content" />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </StyledPopup>
            )}
          </Box>
        </Marker>
      );
    });

  const layerAquaticSpeciesTracking = populationCountData?.length ? (
    <>
      {renderDrawingLines}
      {renderPinPointList}
    </>
  ) : null;

  return { layerAquaticSpeciesTracking };
};
