import { Box, Tooltip } from '@mui/material';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { countOccurrencesArray } from 'common/utils/count';
import CircleBox from 'components/KpDetail/CircleBox';
import { IPhotoIntelligenceData, SeverityColor, SeverityLevelEnum, ToggleEnumType } from 'constants/photoIntelligence';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SeverityLevelProps {
  data: IPhotoIntelligenceData;
}

const SeverityLevel: FC<SeverityLevelProps> = ({ data }) => {
  const { t } = useTranslation();
  const kpData = countOccurrencesArray(data.severityLevels, ToggleEnumType.SEVERITY_LEVELS);
  const { names, severityLevels } = data || {};

  const tooltipHashMap: Record<SeverityLevelEnum, string> = {
    [SeverityLevelEnum.HIGH]: '',
    [SeverityLevelEnum.MEDIUM]: '',
    [SeverityLevelEnum.LOW]: '',
    [SeverityLevelEnum.GROUND_FEATURES]: '',
  };
  severityLevels.forEach((level, index) => {
    tooltipHashMap[level] = names[index];
  });

  const convertedKpData = kpData?.map((item) => ({
    key: item.key,
    label: item.value,
    color: SeverityColor[item.key],
  }));

  const renderTooltip = (key: SeverityLevelEnum) => {
    return tooltipHashMap[key];
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: '90px',
        gap: '4px',
      }}>
      {convertedKpData?.map((item) => (
        <Tooltip key={item.key} title={t(convertTextToMultiLanguage(renderTooltip(item.key), 'photo_intelligence'))}>
          <CircleBox color={item.color} label={item.label} isCollapseSpace />
        </Tooltip>
      ))}
    </Box>
  );
};

export default SeverityLevel;
