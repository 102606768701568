import { Box, FormControlLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { OPTION_ALL } from 'common/defines/constants';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { IPopulationCount } from 'interfaces/fish-migration';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeAnimationStatus,
  changePopulationListSelectedIds,
  fishMigrationSelector,
} from 'store/slices/map-view/fishMigrationAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface SelectIdAreaProps {
  populationTableData: IPopulationCount[];
}

const SelectIdArea: FC<SelectIdAreaProps> = ({ populationTableData }) => {
  const dispatch = useAppDispatch();

  const {
    populationCount: { animationStatus },
  } = useAppSelector(fishMigrationSelector);
  const { levelId } = useAppSelector(mapViewSelector);

  const {
    populationCount: {
      listSelectedIds: { label: selectedIds },
    },
  } = useAppSelector(fishMigrationSelector);
  const { t } = useTranslation();

  const isShowAnimation = animationStatus.find((item) => item.levelId === levelId)?.isAnimate ?? false;

  const listTagId = useMemo(() => {
    return populationTableData.map((item) => item.tagId.toString());
  }, [populationTableData]);

  // initial and reset selectedIds
  useEffect(() => {
    dispatch(
      changePopulationListSelectedIds({
        label: [OPTION_ALL],
        value: listTagId,
      })
    );

    return () => {
      dispatch(
        changePopulationListSelectedIds({
          label: [],
          value: [],
        })
      );
    };
  }, [dispatch, listTagId]);

  const listTagIdOption = [...new Set(listTagId)].map((item) => ({
    value: item,
    label: item,
  }));
  const fullListTagIdOption = [{ value: OPTION_ALL, label: t('trans.all') }, ...listTagIdOption];

  const handleChangeId = (event: SelectChangeEvent<typeof selectedIds>) => {
    const value = event.target.value;
    if (value[value.length - 1] === OPTION_ALL) {
      dispatch(
        changePopulationListSelectedIds({
          label: [OPTION_ALL],
          value: listTagId,
        })
      );
    } else {
      const newSelectedIds =
        typeof value === 'string'
          ? value.split(',')
          : value.filter((item) => item !== OPTION_ALL).map((item) => item.toString());
      dispatch(
        changePopulationListSelectedIds({
          label: newSelectedIds,
          value: newSelectedIds,
        })
      );
    }

    if (value?.length === 0) {
      dispatch(
        changePopulationListSelectedIds({
          label: [OPTION_ALL],
          value: listTagId,
        })
      );
    }
  };

  const handleSwitchButton = (e: any) => {
    const value = e.target.checked;
    if (!levelId) return;
    let newAnimationStatus = [];

    if (value) {
      newAnimationStatus = [...animationStatus, { levelId, isAnimate: true }];
      dispatch(changeAnimationStatus(newAnimationStatus));
    } else {
      newAnimationStatus = animationStatus.filter((item) => item.levelId !== levelId).filter((item) => item.isAnimate);
    }
    dispatch(changeAnimationStatus(newAnimationStatus));
  };
  return (
    <Box display="flex" gap="16px" alignItems="center">
      <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Typography>Id: </Typography>
        <Select
          id="select-id"
          value={selectedIds}
          onChange={handleChangeId}
          size="small"
          sx={{ width: '100%' }}
          multiple>
          {fullListTagIdOption.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box>
        <FormControlLabel
          sx={{ ml: 0.5 }}
          control={<SwitchCustom checked={isShowAnimation} onChange={handleSwitchButton} />}
          label={'Animate'}
        />
      </Box>
    </Box>
  );
};

export default SelectIdArea;
