import Box from '@mui/material/Box';
import CommonModal from 'components/Common/CommonModal';
import { FC } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { IIssue } from '../../../interfaces';
import SwiperBox from './SwiperBox';

interface FullImageModalProps {
  item: IIssue;
  isShow: boolean;
  handleClose: () => void;
  activeIndex: number;
}

const imageSize = {
  height: 600,
  width: 900,
};

const FullImageModal: FC<FullImageModalProps> = ({ item, isShow, handleClose, activeIndex }) => {
  return (
    <CommonModal isShow={isShow} handleClose={handleClose}>
      <TransformWrapper initialScale={1} limitToBounds={true}>
        <TransformComponent>
          <Box
            sx={{
              ...imageSize,
            }}>
            <SwiperBox item={item} isFullSize={true} size={imageSize} activeIndex={activeIndex} />
          </Box>
        </TransformComponent>
      </TransformWrapper>
    </CommonModal>
  );
};

export default FullImageModal;
