interface IGeometry {
  longitude: number;
  latitude: number;
}

export interface KmlProperty {
  name: string;
  geometries: IGeometry[];
  [key: string]: IGeometry[] | string | number;
}
export interface KmlInfo {
  name: string;
  description?: string;
  properties: KmlProperty[];
}

export const generateKmlForPoint = (kmlInfo: KmlInfo) => {
  const { name, description, properties } = kmlInfo;

  const generateExtendedData = (key: string, value: string) => {
    return `   <Data name="${key}">
            <value>${value}</value>
          </Data>`;
  };

  const generateListExtendedData = (data: KmlProperty) => {
    const { name, geometries, ...rest } = data;

    const generateTagType = () => {
      switch (geometries.length) {
        case 2:
          return 'LineString';
        case 1:
        default:
          return 'Point';
      }
    };

    return `<Placemark>
        <name>${name}</name>
        <ExtendedData>
          ${Object.entries(rest).map(([key, value]) => generateExtendedData(key, value.toString()))}
        </ExtendedData>
        <${generateTagType()}>
          <coordinates>
          ${geometries
            .map((item) => {
              const { longitude, latitude } = item;
              return `${longitude},${latitude},0`;
            })
            .join(', ')}
          </coordinates>
        </${generateTagType()}>
      </Placemark>`;
  };

  return `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2">
    <Document>
      <name>${name}</name>
      ${description ? `<description>${description}</description>` : ''}
      ${properties.map((prop) => generateListExtendedData(prop))}
    </Document>
  </kml>`;
};
