import PlaceIcon from '@mui/icons-material/Place';
import { Box } from '@mui/material';
import { MousePositionEnum, PopulationCountTabEnum } from 'common/defines/constants';
import {
  extractFishSpeciesName,
  formatAndSortPopulationCountData,
  generateFilteredDataWithDate,
  generateFilteredDataWithId,
  generateFilteredFlightCommissionDates,
} from 'common/utils/fish-migration';
import PopupParagraph from 'components/Common/PopupParagraph';
import StyledPopup from 'components/MapView/StyledPopup';
import { QUERY_KEY } from 'constants/constants';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import useCheckingPopupPosition from 'hooks/common/useCheckingPopupPosition';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getAllPopulationCountAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeFocusedTagState, fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import DrawingLines from './DrawingLines';
import HeatMapDisplay from './HeatMapDisplay';

const fixedSize = {
  height: 200,
  width: 300,
};

export const usePopulationCountAnalytics = ({ mapRef }: any) => {
  const [hoveredMarkerDetail, setHoveredMarkerDetail] = useState<IPopulationCount>();
  const { t } = useTranslation();
  const { analyticId, levelId } = useAppSelector(mapViewSelector);

  const {
    populationCount: {
      focusTag,
      filteredStartDate: { value: selectedStartDate },
      filteredEndDate: { value: selectedEndDate },
      listSelectedIds: { value: selectedIds },
      flightMissionDates: { value: flightMissionDates },
      selectedTab,
      heatmapStatus,
    },
  } = useAppSelector(fishMigrationSelector);
  const dispatch = useAppDispatch();

  const isShowHeatmap = heatmapStatus.find((item) => item.levelId === levelId)?.isShowHeatmap ?? false;

  const mousePosition = useRef<MousePositionEnum>(MousePositionEnum.TOP_CENTER);
  const { detectMousePosition } = useCheckingPopupPosition({});

  const { data: populationCountResponse } = useQuery(
    [QUERY_KEY.POPULATION_COUNT_ANALYTIC, analyticId],
    () => getAllPopulationCountAnalytic(analyticId || ''),
    {
      enabled: !!analyticId,
    }
  );

  const listContent = (item: IPopulationCount) => {
    const contentList = [
      { key: 'trans.id', value: item.tagId },
      { key: 'trans.species', value: extractFishSpeciesName(item.image) },
      { key: 'trans.date', value: moment(item.date).format(DATE_VISUALIZE_FORMAT) },
    ];

    if (selectedTab === PopulationCountTabEnum.POPULATION) {
      contentList.push({ key: 'trans.strength', value: item.power });
    }

    return contentList;
  };

  const populationCountData = populationCountResponse?.data as IPopulationCount[];
  const formattedData = formatAndSortPopulationCountData(populationCountData);

  //move to exact position when click tagId
  useEffect(() => {
    const {
      isFlyDone,
      info: { id, lat, long },
    } = focusTag;
    if (!isFlyDone) {
      mapRef.current?.flyTo({
        center: [long, lat],
        essential: true,
        speed: 1,
        curve: 1,
        zoom: 20,
      });
      dispatch(changeFocusedTagState({ info: { id, long, lat }, isFlyDone: true }));
    }
  }, [dispatch, focusTag, mapRef]);

  const calculateOffsetYWithPosition = () => {
    if (mousePosition.current.includes('top')) return 10;
    else return -30;
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      mousePosition.current = detectMousePosition(e);
    },
    [detectMousePosition]
  );

  const filteredDataWithFlightCommissionDates = generateFilteredFlightCommissionDates(
    formattedData,
    flightMissionDates
  );

  const filteredDataWithId = generateFilteredDataWithId(formattedData || [], selectedIds);
  const filteredDataWithDate = generateFilteredDataWithDate(filteredDataWithId, selectedStartDate, selectedEndDate);

  const filteredData =
    selectedTab === PopulationCountTabEnum.POPULATION ? filteredDataWithFlightCommissionDates : filteredDataWithDate;

  const renderPinPointList = filteredData?.map((item) => {
    return (
      <>
        <Marker longitude={item.lon} latitude={item.lat} anchor="bottom" key={item._id}>
          <Box
            position="relative"
            onMouseMove={handleMouseMove}
            sx={{ height: '35px', width: '35px', cursor: 'default' }}>
            <PlaceIcon
              fontSize="large"
              sx={{ color: item.color }}
              onMouseEnter={() => setHoveredMarkerDetail(item)}
              onMouseLeave={() => setHoveredMarkerDetail(undefined)}
            />
          </Box>
        </Marker>
        {item._id === hoveredMarkerDetail?._id && (
          <StyledPopup
            longitude={item.lon}
            latitude={item.lat}
            anchor={mousePosition.current}
            closeButton={false}
            closeOnClick={false}
            style={{ ...fixedSize, maxWidth: 'unset' }}
            offset={[0, calculateOffsetYWithPosition()]}>
            <Box>
              <Box
                sx={{
                  border: 0,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  backgroundColor: (theme) => theme.palette.background.default,
                }}>
                <Box sx={{ p: '8px' }}>
                  <Box sx={{}}>
                    <img src={item.image} alt="fish" height={'100%'} width={'100%'} style={{ borderRadius: '8px' }} />
                  </Box>
                  {listContent(item).map((row, index) => (
                    <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <PopupParagraph value={t(row.key)} variant="label" />
                      <PopupParagraph value={row.value} variant="content" />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </StyledPopup>
        )}
      </>
    );
  });

  const layerPopulationCount = (
    <>
      {selectedTab === PopulationCountTabEnum.TRACKING && <DrawingLines filteredDataWithDate={filteredDataWithDate} />}
      {!isShowHeatmap && renderPinPointList}
      {isShowHeatmap && <HeatMapDisplay filteredData={filteredData} />}
    </>
  );

  return { layerPopulationCount };
};
