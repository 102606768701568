import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Box, Typography, useTheme } from '@mui/material';
import { MousePositionEnum } from 'common/defines/constants';
import StyledPopup from 'components/MapView/StyledPopup';
import useCheckingPopupPosition from 'hooks/common/useCheckingPopupPosition';
import { FC, MouseEvent, useRef, useState } from 'react';
import { Marker } from 'react-map-gl';
import { IIssue } from '../../../interfaces';
import FullImageModal from './FullImageModal';
import SwiperBox from './SwiperBox';

interface IssueMarkProps {
  item: IIssue;
}

const fixedSize = {
  height: 135,
  width: 290,
};

const IssueMark: FC<IssueMarkProps> = ({ item }) => {
  const [hoverIssueId, setHoverIssueId] = useState<string>('');
  const [isMoveIntoPopup, setIsMoveIntoPopup] = useState<boolean>(false);
  const [isShowFullImageModal, setIsShowFullImageModal] = useState<boolean>(false);
  const theme = useTheme();
  const mousePosition = useRef<MousePositionEnum>(MousePositionEnum.TOP_CENTER);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const isShowPopup = hoverIssueId === item._id || isMoveIntoPopup;

  const { detectMousePosition } = useCheckingPopupPosition({});

  const handleLeaveMarker = () => {
    if (!isMoveIntoPopup) {
      setHoverIssueId('');
    } else {
      setHoverIssueId(item._id);
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isShowPopup) {
      mousePosition.current = detectMousePosition(e);
    }
  };

  const calculateOffsetYWithPosition = () => {
    if (mousePosition.current.includes('top')) return 0;
    else return -36;
  };

  return (
    <>
      <Marker
        key={item._id}
        longitude={item.geometry.coordinates[0]}
        latitude={item.geometry.coordinates[1]}
        offset={[0, 0]}>
        <Box sx={{ position: 'relative' }} onMouseMove={handleMouseMove}>
          <RoomOutlinedIcon
            onMouseEnter={() => setHoverIssueId(item._id)}
            onMouseLeave={handleLeaveMarker}
            sx={{
              color: item.border?.color || theme.palette.primary.main,
              fontSize: '36px',
              cursor: 'pointer',
              position: 'absolute',
              top: 'calc(50% - 36px)',
              left: 'calc(50% - 18px)',
              strokeWidth: 1,
            }}
          />
        </Box>
      </Marker>
      {isShowPopup && (
        <StyledPopup
          longitude={item.geometry.coordinates[0]}
          latitude={item.geometry.coordinates[1]}
          anchor={mousePosition.current}
          closeButton={false}
          closeOnClick={false}
          style={{ ...fixedSize, maxWidth: 'unset' }}
          offset={[0, calculateOffsetYWithPosition()]}>
          <Box>
            <Box
              onMouseLeave={() => {
                setHoverIssueId('');
                setIsMoveIntoPopup(false);
              }}
              onMouseEnter={() => setIsMoveIntoPopup(true)}
              sx={{
                border: 0,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '8px',
                cursor: 'pointer',
                backgroundColor: (theme) => theme.palette.background.default,
              }}>
              <SwiperBox
                item={item}
                size={fixedSize}
                setIsShowFullImageModal={setIsShowFullImageModal}
                setActiveIndex={setActiveIndex}
              />
              <Box sx={{ p: '8px' }}>
                <Typography style={{ color: theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043' }}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        </StyledPopup>
      )}
      {isShowFullImageModal && (
        <FullImageModal
          item={item}
          isShow={isShowFullImageModal}
          handleClose={() => setIsShowFullImageModal(false)}
          activeIndex={activeIndex}
        />
      )}
    </>
  );
};

export default IssueMark;
