import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Collapse, Grid, InputLabel } from '@mui/material';
import { AxiosResponse } from 'axios';
import { DATA_RASTERS, LABEL_DATA_RASTERS, LayerTypeEnum, TypeRaster } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import SelectFile from 'components/Common/SelectFile';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { UseMutationResult } from 'react-query';

interface FixedRastersProps {
  values: any;
  sensorId: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  typeRasterName: TypeRaster;
  updateAnalyticMutation: UseMutationResult;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const FixedRasters: FC<FixedRastersProps> = ({
  values,
  sensorId,
  setFieldValue,
  typeRasterName,
  updateAnalyticMutation,
  deleteFileGeojson,
}) => {
  const [isShowRaster, setShowRaster] = useState<boolean>(false);
  const classes = useLayerSelectCardStyle();

  return (
    <Box>
      <Button
        style={{ padding: 0 }}
        onClick={() => setShowRaster(!isShowRaster)}
        classes={{ root: classes.buttonTextStyle }}>
        {!isShowRaster ? <ChevronRightIcon /> : <KeyboardArrowDownIcon />}
        {t('trans.raster')}
      </Button>
      <Collapse in={isShowRaster} timeout="auto" sx={{ px: 1 }}>
        <Box sx={{ paddingLeft: '12px' }}>
          {DATA_RASTERS[typeRasterName].map((item) => (
            <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
              <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                {t(convertTextToMultiLanguage(LABEL_DATA_RASTERS[item as LayerTypeEnum], 'label_raster'))}
              </InputLabel>
              <SelectFile
                acceptFile={new RegExp(/.zip$/)}
                fileSelected={values[item]}
                disabled={updateAnalyticMutation.isLoading}
                onSelectFile={(file) => setFieldValue(item, file)}
                analysisId={sensorId}
                layerType={item}
                deleteFileGeojson={deleteFileGeojson}
                isCommonRaster
              />
            </Grid>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default FixedRasters;
