import { styled, TableCell } from '@mui/material';

export const CustomTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '8px',
  lineHeight: 1.4,
}));

export const CustomTableBodyCell = styled(TableCell)(() => ({
  padding: '8px',
}));
