import { styled } from '@mui/material';
import { Popup } from 'react-map-gl';

const StyledPopup = styled(Popup)({
  '& .mapboxgl-popup-tip': {
    display: 'none',
  },
  '& .mapboxgl-popup-content': {
    borderRadius: '8px',
    padding: 0,
  },
});

export default StyledPopup;
