import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { RasterLayerDTO } from 'common/defines/analytic';
import { LayerTypeEnum } from 'common/defines/constants';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface RasterCollapseProps {
  listRasterData: RasterLayerDTO[] | undefined;
  handleSwitchCommonRaster: (item: RasterLayerDTO) => void;
  getNameRaster: (_item: RasterLayerDTO) => string;
}

const RasterCollapse: FC<RasterCollapseProps> = ({ listRasterData, handleSwitchCommonRaster, getNameRaster }) => {
  const [isShowRaster, setIsShowRaster] = useState<boolean>(true);

  const { isRTL } = useCheckRTL();
  const classes = useLayerSelectCardStyle();
  const { layerTypeList } = useAppSelector(mapViewSelector);
  const { t } = useTranslation();

  const listCustomRasters = listRasterData
    ?.filter((item) => item.orderNumber)
    .sort((a, b) => a.orderNumber - b.orderNumber);
  const listFixedRasters = listRasterData?.filter((item) =>
    Object.values(LayerTypeEnum).includes(item.layerType as LayerTypeEnum)
  );

  return (
    <>
      <Button
        startIcon={
          isShowRaster ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowRaster(!isShowRaster)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{
            marginRight: isRTL ? 0 : 'auto',
            marginLeft: isRTL ? 'auto' : 0,
            fontWeight: 'bold',
            fontSize: '13px !important',
            textAlign: isRTL ? 'right' : 'left',
          }}>
          {t('trans.raster')}
        </Typography>
      </Button>
      <Collapse in={isShowRaster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
        {listCustomRasters?.length ? (
          <Typography className={classes.buttonTextStyle} sx={{ fontSize: '14px', fontWeight: 600 }}>
            {t('trans.multi_layer')}
          </Typography>
        ) : null}
        {listCustomRasters?.map((item: RasterLayerDTO) => (
          <Box key={item._id} sx={{ ml: '8px' }}>
            {item?.layerType && (
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  // labelPlacement={isRTL ? 'start' : 'end'}
                  control={
                    <SwitchCustom
                      checked={layerTypeList.some((i) => i._id === item._id)}
                      onClick={() => {
                        handleSwitchCommonRaster(item);
                      }}
                    />
                  }
                  label={<Typography classes={{ root: classes.selectText }}>{getNameRaster(item)}</Typography>}
                />
              </Grid>
            )}
          </Box>
        ))}

        {listFixedRasters?.length ? (
          <Typography className={classes.buttonTextStyle} sx={{ fontSize: '14px', fontWeight: 600 }}>
            {t('trans.single_layer')}
          </Typography>
        ) : null}
        {listFixedRasters?.map((item: RasterLayerDTO) => (
          <Box key={item._id} sx={{ ml: '8px' }}>
            {item?.layerType && (
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  // labelPlacement={isRTL ? 'start' : 'end'}
                  control={
                    <SwitchCustom
                      checked={layerTypeList.some((i) => i._id === item._id)}
                      onClick={() => {
                        handleSwitchCommonRaster(item);
                      }}
                    />
                  }
                  label={<Typography classes={{ root: classes.selectText }}>{getNameRaster(item)}</Typography>}
                />
              </Grid>
            )}
          </Box>
        ))}
      </Collapse>
    </>
  );
};

export default RasterCollapse;
