import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Button, Collapse, InputLabel, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { LayerTypeEnum, TypeRaster } from 'common/defines/constants';
import SelectFile from 'components/Common/SelectFile';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import { changeLayerTypeName, updateRasterPosition } from 'services/clients/apiClient.services';

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  width: '100%',
  minHeight: isDragging ? '80px' : '100%',
  background: isDragging ? 'lightgreen' : undefined,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : undefined,
  width: '100%',
});

enum EditMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

interface ICustomRaster {
  _id: string;
  value: string;
  mode: EditMode;
  orderNumber: number;
  temporaryValue: string;
}

interface CustomRastersProps {
  values: Record<string, any>;
  sensorId: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  typeRasterName: TypeRaster;
  updateAnalyticMutation: UseMutationResult;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const CustomRasters: FC<CustomRastersProps> = ({
  values,
  sensorId,
  setFieldValue,
  updateAnalyticMutation,
  deleteFileGeojson,
}) => {
  const [listCustomRaster, setListCustomRaster] = useState<ICustomRaster[]>([]);
  const [isShowRaster, setShowRaster] = useState<boolean>(false);

  const classes = useLayerSelectCardStyle();
  const isUploadedAllFiles = listCustomRaster.every((item) => item._id);

  useEffect(() => {
    const customRasters: ICustomRaster[] = [];
    Object.entries(values).forEach(([key, value]) => {
      if (Object.values(LayerTypeEnum).includes(key as LayerTypeEnum)) {
      } else if (value?.path) {
        customRasters.push({
          mode: EditMode.VIEW,
          value: key,
          orderNumber: value.orderNumber ?? Infinity,
          _id: value._id ?? '',
          temporaryValue: key,
        });
      }
    });
    customRasters.sort((a, b) => a.orderNumber - b.orderNumber);
    setListCustomRaster(customRasters);
  }, [values]);

  const updateRasterPositionMutation = useMutation(updateRasterPosition, {
    onSuccess() {
      toast.success('Reorder Raster Successfully');
    },
    onError() {
      toast.error('Reorder Raster Failed');
    },
  });

  const changeLayerTypeNameMutation = useMutation(changeLayerTypeName, {
    onSuccess() {
      toast.success('Change Raster Name Successfully');
    },
    onError() {
      toast.error('Change Raster Name Failed');
    },
  });

  const onAddRaster = () => {
    setListCustomRaster((prev) => [
      ...prev,
      { mode: EditMode.EDIT, value: '', orderNumber: Infinity, _id: '', temporaryValue: '' },
    ]);
  };

  const handleChangeTextField = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    customRaster: ICustomRaster,
    index: number
  ) => {
    const enteredValue = e.target.value;
    const newListCustomRaster = listCustomRaster.map((item, i) => ({
      ...item,
      temporaryValue: i === index ? enteredValue : item.temporaryValue,
      value: i === index && !customRaster._id ? enteredValue : item.value,
    }));
    setListCustomRaster(newListCustomRaster);
  };

  const handleSaveTextField = (raster: ICustomRaster, index: number) => {
    const newListCustomRaster = listCustomRaster.map((item, i) => ({
      ...item,
      mode: i === index ? EditMode.VIEW : item.mode,
    }));
    setListCustomRaster(newListCustomRaster);

    if (raster._id) {
      changeLayerTypeNameMutation.mutate({
        name: raster.temporaryValue,
        rasterId: raster._id,
      });
    }
  };

  const handleEditTextField = (index: number) => {
    const newListCustomRaster = listCustomRaster.map((item, i) => ({
      ...item,
      mode: i === index ? EditMode.EDIT : item.mode,
    }));
    setListCustomRaster(newListCustomRaster);
  };

  const handleDeleteRaster = (index: number) => {
    const newListCustomRaster = listCustomRaster.filter((_, i) => i !== index);
    setListCustomRaster(newListCustomRaster);
  };

  // a little function to help us with reordering the result
  const reorder = (listCustomRaster: ICustomRaster[], startIndex: number, endIndex: number) => {
    const result = Array.from(listCustomRaster);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(listCustomRaster, result.source.index, result.destination.index);

    updateRasterPositionMutation.mutate(items.map((item, index) => ({ id: item._id, positionNumber: index + 1 })));

    setListCustomRaster(items);
  };

  const renderRasterBar = (customRaster: ICustomRaster, index: number) => {
    return (
      <Box key={index} display="flex" alignItems="center" mt={2} justifyContent="space-between" gap="80px">
        <Box position="relative" flex={1}>
          <Box display="flex" alignItems="center">
            {isUploadedAllFiles && <UnfoldMoreIcon sx={{ fontSize: '30px' }} />}
            {customRaster.mode === EditMode.VIEW ? (
              <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                {customRaster.temporaryValue}
              </InputLabel>
            ) : (
              <TextField
                value={customRaster.temporaryValue}
                size="small"
                onChange={(e) => handleChangeTextField(e, customRaster, index)}
                fullWidth
              />
            )}
          </Box>

          {customRaster.mode === EditMode.VIEW ? (
            <Box display="flex" gap="4px" sx={{ position: 'absolute', right: 0, top: '-20px' }}>
              <DriveFileRenameOutlineOutlinedIcon
                sx={{ fontSize: '20px', cursor: 'pointer' }}
                color="action"
                onClick={() => handleEditTextField(index)}
              />
              {!customRaster._id && (
                <DeleteForeverOutlinedIcon
                  color="error"
                  sx={{ fontSize: '20px', cursor: 'pointer' }}
                  onClick={() => handleDeleteRaster(index)}
                />
              )}
            </Box>
          ) : (
            <SaveOutlinedIcon
              sx={{
                position: 'absolute',
                right: 0,
                top: '-20px',
                fontSize: '20px',
                cursor: 'pointer',
              }}
              onClick={() => handleSaveTextField(customRaster, index)}
              color="primary"
            />
          )}
        </Box>
        <Box sx={{ pointerEvents: customRaster.mode === EditMode.VIEW ? 'auto' : 'none' }}>
          <SelectFile
            acceptFile={new RegExp(/.zip$/)}
            fileSelected={values[customRaster.value]}
            disabled={updateAnalyticMutation.isLoading}
            onSelectFile={(file) => setFieldValue(customRaster.value, file)}
            analysisId={sensorId}
            layerType={customRaster.value}
            deleteFileGeojson={deleteFileGeojson}
            isCommonRaster
            orderNumber={index + 1}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ my: '8px' }}>
      <Button
        style={{ padding: 0 }}
        onClick={() => setShowRaster(!isShowRaster)}
        classes={{ root: classes.buttonTextStyle }}>
        {!isShowRaster ? <ChevronRightIcon /> : <KeyboardArrowDownIcon />}
        {t('trans.custom_rasters')}
      </Button>
      <Collapse in={isShowRaster} timeout="auto" sx={{ px: 1 }}>
        <Box sx={{ paddingLeft: '12px' }}>
          <Box display="flex" gap="4px" mt="8px" mb="24px">
            <Button variant="outlined" size="small" color="neutral" onClick={onAddRaster}>
              <AddIcon color="primary" />
            </Button>
          </Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {listCustomRaster.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id || '0'}
                      index={index}
                      isDragDisabled={!isUploadedAllFiles}>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          {renderRasterBar(item, index)}
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CustomRasters;
