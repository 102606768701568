import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Collapse, Grid, InputLabel } from '@mui/material';
import { AxiosResponse } from 'axios';
import { LABEL_DATA_RASTERS, LayerTypeEnum } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import SelectFile from 'components/Common/SelectFile';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

interface VectorFormListProps {
  values: any;
  sensorId: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  updateAnalyticMutation: UseMutationResult;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const VectorFormList: FC<VectorFormListProps> = ({
  values,
  sensorId,
  setFieldValue,
  updateAnalyticMutation,
  deleteFileGeojson,
}) => {
  const [isShowVector, setShowVector] = useState<boolean>(false);
  const classes = useLayerSelectCardStyle();
  const { t } = useTranslation();

  const vectorFormList = [
    { key: 1, layerType: LayerTypeEnum.VECTOR_CONTOUR, acceptFile: new RegExp(/.zip$/) },
    { key: 2, layerType: LayerTypeEnum.STREAM_LINE_ORDER, acceptFile: new RegExp(/.geojson$/) },
    { key: 3, layerType: LayerTypeEnum.LINES, acceptFile: new RegExp(/.geojson$/) },
  ];

  return (
    <Box>
      <Button
        style={{ padding: 0 }}
        onClick={() => setShowVector(!isShowVector)}
        classes={{ root: classes.buttonTextStyle }}>
        {!isShowVector ? <ChevronRightIcon /> : <KeyboardArrowDownIcon />}
        {t('trans.vector')}
      </Button>
      <Collapse in={isShowVector} timeout="auto" sx={{ px: 1 }}>
        <Box sx={{ paddingLeft: '12px' }}>
          {vectorFormList.map((item) => (
            <Grid key={item.key} display="flex" alignItems="center" mt={2} justifyContent="space-between">
              <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                {t(convertTextToMultiLanguage(LABEL_DATA_RASTERS[item.layerType], 'mapView'))}
              </InputLabel>
              <SelectFile
                acceptFile={item.acceptFile}
                fileSelected={values[item.layerType]}
                disabled={updateAnalyticMutation.isLoading}
                onSelectFile={(file) => setFieldValue(item.layerType, file)}
                analysisId={sensorId}
                layerType={item.layerType}
                deleteFileGeojson={deleteFileGeojson}
              />
            </Grid>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default VectorFormList;
