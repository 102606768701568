import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { PeriodEnum } from 'common/defines/clients';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PeriodFilterProps {
  selectedPeriod: string;
  onPeriodChange: (period: string) => void;
  disabled?: boolean;
}

const PeriodDateFilter: FC<PeriodFilterProps> = ({ selectedPeriod, onPeriodChange, disabled }) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onPeriodChange(event.target.value as string);
  };
  const { t } = useTranslation();
  const periods = [
    { value: '', label: t('photo_intelligence.none') },
    { value: PeriodEnum.ONE_MONTH, label: t('trans.1_months') },
    {
      value: PeriodEnum.THREE_MONTHS,
      label: t('trans.3_months'),
    },
    {
      value: PeriodEnum.SIX_MONTHS,
      label: t('trans.6_months'),
    },
    { value: PeriodEnum.TWELVE_MONTHS, label: t('trans.12_months') },
    { value: PeriodEnum.YEAR_TO_DATE, label: t('photo_intelligence.year_to_date') },
    { value: PeriodEnum.LAST_YEAR, label: t('photo_intelligence.last_year') },
  ];

  return (
    <Box>
      <Select
        value={selectedPeriod}
        onChange={handleChange}
        displayEmpty
        fullWidth
        sx={{ width: '250px', height: '35px' }}
        disabled={disabled}>
        {periods.map((period) => (
          <MenuItem key={period.value} value={period.value}>
            {period.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default PeriodDateFilter;
