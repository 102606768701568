import { Box, Grid, Tooltip } from '@mui/material';
import { LandUsageIcon } from 'assets/icons/MapView';
import { IDataAnalysis } from 'common/defines/clients';
import { MapEnum, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { convertAnalyticNameToMultiLanguage } from 'common/utils/convert';
import { SelectDateAnalytics } from 'components/Common/SelectDateAnalytics';
import { QUERY_KEY } from 'constants/constants';
import useCheckingEnableAllLevels from 'hooks/common/useCheckingEnableAllLevels';
import useEnableSummaryInHigherLevels from 'hooks/common/useEnableSummaryInHigherLevels';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { sendGetDataAnalysisByLevelIdDate } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearCo2SequestrationAnalytics } from 'store/slices/map-view/co2SequestrationAnalytics';
import { clearGapAnalytics } from 'store/slices/map-view/gapAnalytics';
import { clearPlantHealthOilPailmAnalytics } from 'store/slices/map-view/plantHealthOilPailmAnalytics';
import { changeDataPointClicked } from 'store/slices/map-view/standCountAnalytics';
import { clearTreeHeightAnalytics } from 'store/slices/map-view/treeHeightAnalytics';
import { clearTreeTiltFallenAnalytics } from 'store/slices/map-view/treeTiltFallenAnalytics';
import {
  changeLayer3D,
  changeParentAnalyticsId,
  clearData,
  mapViewSelector,
  selectRasterLayer,
} from 'store/slices/mapViewSlice';
import { changeAnalyticName, changeAnalyticReLabel, rightBarSelector } from 'store/slices/rightBarSlice';
import { clearChlorophyllAnalytics } from '../../../../store/slices/map-view/chlorophyllAnalytics';
import { clearCircumferenceAnalytics } from '../../../../store/slices/map-view/circumferenceAnalytics';
import { clearCrownAreaAnalytics } from '../../../../store/slices/map-view/crownAreaAnalytics';
import { clearPlantStressAnalytics } from '../../../../store/slices/map-view/plantStressAnalytics';
import { clearVigorAnalytics } from '../../../../store/slices/map-view/vigorAnalytics';
import { clearWaterUptakeAnalytics } from '../../../../store/slices/map-view/waterUptakeAnalytics';
import { clearWeedInvasionAnalytics } from '../../../../store/slices/map-view/weedInvasionAnalytics';
import { useAnalyticTabStyle } from '../../MapViewStyle';
import PhotoIntelligenceSummaryTableCollapse from '../AnalyticsHigherLevels/photo-intelligence-summary-table-collapse';
import { AnalyticCardTab } from './AnalyticCardTab';
import { CategoriesTab } from './Category';
import PhotoIntelligenceAnalytics from './Category/PhotoIntelligenceAnalytics';
import { SwitchRaster } from './Layer/SwitchRaster';
import PhotoIntelligenceCollapse from './PhotoIntelligenceCollapse';
import TemporalButton from './TemporalButton';
import TemporalModal from './TemporalModal';

export const AnalyticsTab = () => {
  const [isShowTemporalModal, setIsShowTemporalModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const classes = useAnalyticTabStyle();
  const dispatch = useAppDispatch();
  const { dateAnalyticSelected, levelId, analyticId, ratersSelected } = useAppSelector(mapViewSelector);
  const { analyticReLabel, analyticName } = useAppSelector(rightBarSelector);
  const { isEnableAnalyticAllLevels } = useCheckingEnableAllLevels();
  const { isShowPhotoIntelligenceSummaryInHigherLevel } = useEnableSummaryInHigherLevels();

  const { data } = useQuery(
    [QUERY_KEY.DATA_ANALYSIS, levelId, dateAnalyticSelected],
    () => sendGetDataAnalysisByLevelIdDate(levelId || '', dateAnalyticSelected || ''),
    {
      enabled: !!dateAnalyticSelected && !!levelId,
    }
  );

  // with case analytic = 'Land Use', only active when uploaded polygon file
  const isAnalyticActive = (_item: IDataAnalysis) => {
    const uploadedLength = _item?.uploaded?.length;
    if (_item.name === TYPE_ANALYTICS_MAP_VIEW.AQUATIC_SPECIES_TRACKING) {
      return true;
    }
    if (_item.name !== TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS) {
      return !!uploadedLength;
    } else {
      return !!uploadedLength && _item.uploaded?.some((uploadFile) => uploadFile?.geoJsonType === 'POLYGON');
    }
  };

  const dataAnalysis: IDataAnalysis[] = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const clearDataAnalytics = useCallback(() => {
    dispatch(clearCircumferenceAnalytics());
    dispatch(clearVigorAnalytics());
    dispatch(clearPlantStressAnalytics());
    dispatch(clearCrownAreaAnalytics());
    dispatch(clearWaterUptakeAnalytics());
    dispatch(clearChlorophyllAnalytics());
    dispatch(clearGapAnalytics());
    dispatch(clearTreeTiltFallenAnalytics());
    dispatch(clearPlantHealthOilPailmAnalytics());
    dispatch(clearWeedInvasionAnalytics());
    dispatch(clearTreeHeightAnalytics());
    dispatch(clearCo2SequestrationAnalytics());
    dispatch(clearData());
    dispatch(changeDataPointClicked(undefined));
    dispatch(selectRasterLayer([]));
  }, [dispatch]);

  // clear all data when change date
  useEffect(() => {
    clearDataAnalytics();
  }, [clearDataAnalytics, dateAnalyticSelected]);

  const changeAnalyticNameData = useCallback(
    (dataTab: IDataAnalysis) => {
      dispatch(changeAnalyticName(dataTab.name));
      dispatch(changeAnalyticReLabel(dataTab.relabel || ''));
    },
    [dispatch]
  );

  const handleChange = useCallback(
    (dataTab: IDataAnalysis) => {
      clearDataAnalytics();
      if (dataTab._id === analyticId) {
        changeAnalyticNameData(dataTab);
        return;
      }
      dispatch(changeParentAnalyticsId({ parentId: dataTab.parentId, analyticId: dataTab._id }));
      changeAnalyticNameData(dataTab);
      if (dataTab.name !== TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS) dispatch(changeLayer3D(false));
    },
    [analyticId, changeAnalyticNameData, clearDataAnalytics, dispatch]
  );

  const getAnalyticName = (data: IDataAnalysis) => {
    const name = data.relabel || data.name;
    return t(convertAnalyticNameToMultiLanguage(name));
  };

  const getIsSelected = (item: IDataAnalysis) => {
    if (analyticName !== item.name) return false;
    // If redux has analyticReLabel, item must has it, else no
    return !!analyticReLabel ? analyticReLabel === item.relabel : !item.relabel;
  };

  const renderAnalyticTooltip = (_item: IDataAnalysis) => {
    return isAnalyticActive(_item) ? getAnalyticName(_item) : t('trans.missing_analytic_files');
  };

  return (
    <Grid container className={classes.tabContainer} direction="column" width="100%">
      {levelId && (
        <Grid container sx={{ mb: '20px' }}>
          <Grid item xs={8}>
            <SelectDateAnalytics levelId={levelId} />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', pr: '4px' }}>
            <TemporalButton setIsShowTemporalModal={setIsShowTemporalModal} mode={MapEnum.MAP_VIEW} />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={0.5} columns={24}>
        {dataAnalysis.map((_item: IDataAnalysis) => {
          return (
            <Tooltip title={renderAnalyticTooltip(_item)} placement="bottom" key={_item._id}>
              <Grid item xs={4}>
                <AnalyticCardTab
                  key={_item._id}
                  icon={<LandUsageIcon width="28px" height="28px" isSelected={getIsSelected(_item)} />}
                  label={getAnalyticName(_item)}
                  isSelected={getIsSelected(_item)}
                  onSelect={() =>
                    getIsSelected(_item) ? handleChange({ _id: '', name: '', parentId: '' }) : handleChange(_item)
                  }
                  onSelectWithAssociatedKp={() => handleChange(_item)}
                  isAnalyticActive={isAnalyticActive(_item)}
                />
              </Grid>
            </Tooltip>
          );
        })}
      </Grid>
      <Box>
        <SwitchRaster analyticType={analyticName} isShowInlineBlock={false} />
      </Box>
      {isEnableAnalyticAllLevels && ratersSelected.length > 0 && <CategoriesTab analyticType={analyticName} />}

      {isShowPhotoIntelligenceSummaryInHigherLevel && (
        <>
          <PhotoIntelligenceCollapse />
          <PhotoIntelligenceSummaryTableCollapse />
        </>
      )}

      {/* Photo intelligence analytic doesn't need any ortho image to be displayed  */}
      {isEnableAnalyticAllLevels && <PhotoIntelligenceAnalytics listDataAnalysis={dataAnalysis} />}

      {isShowTemporalModal && (
        <TemporalModal isShow={true} handleClose={() => setIsShowTemporalModal(false)} mode={MapEnum.MAP_VIEW} />
      )}
    </Grid>
  );
};
