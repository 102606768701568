import { PeriodEnum } from 'common/defines/clients';
import moment from 'moment';

export const generateComparedTime = (selectedTimeFrame: PeriodEnum) => {
  switch (selectedTimeFrame) {
    case PeriodEnum.ONE_MONTH: {
      return moment().subtract(1, 'month');
    }
    case PeriodEnum.SIX_MONTHS: {
      return moment().subtract(6, 'months');
    }
    case PeriodEnum.TWELVE_MONTHS: {
      return moment().subtract(12, 'months');
    }
    case PeriodEnum.MAX:
    default: {
      return moment().subtract(100, 'years');
    }
  }
};
