import { Typography } from '@mui/material';
import { FC } from 'react';

interface PopupParagraphProps {
  value: string | number;
  variant: 'label' | 'content';
}

const PopupParagraph: FC<PopupParagraphProps> = ({ value, variant }) => {
  if (variant === 'label') {
    return (
      <Typography
        component="span"
        sx={{ fontSize: '12px', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#9E9E9E') }}>
        {value}
      </Typography>
    );
  }

  if (variant === 'content') {
    return (
      <Typography
        component="span"
        sx={{
          color: (theme) => (theme.palette.mode === 'dark' ? '#9E9E9E' : '#616161'),
          fontSize: '12px',
        }}>
        {value}
      </Typography>
    );
  }
  return null;
};

export default PopupParagraph;
