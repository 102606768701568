import {
  along as turfAlong,
  bearing as turfBearing,
  distance as turfDistance,
  Feature,
  LineString,
  lineString as turfLineString,
  point as turfPoint,
  Properties,
} from '@turf/turf';
import { IPopulationCount } from 'interfaces/fish-migration';
import { FC, useEffect, useRef, useState } from 'react';
import { Layer, Source } from 'react-map-gl';

export const ANIMATION_STEPS = 300;

interface DrawingAnimationSymbolProps {
  groupDataWithPeriod: IPopulationCount[][];
}

const DrawingAnimationSymbol: FC<DrawingAnimationSymbolProps> = ({ groupDataWithPeriod }) => {
  const [positionIndex, setPositionIndex] = useState<number>(0);
  const animationRef = useRef<any>(null);

  useEffect(() => {
    const animate = () => {
      setPositionIndex((prevOffset) => (prevOffset + 1) % ANIMATION_STEPS); // Adjust speed by changing increment
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  const generateListSegments = (lineDistance: number, line: Feature<LineString, Properties>) => {
    const listSegments = [];

    for (let i = 0; i < lineDistance; i += lineDistance / ANIMATION_STEPS) {
      const segment = turfAlong(line, i);
      listSegments.push(segment);
    }

    return listSegments;
  };

  const geoJsonData: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: groupDataWithPeriod?.map((listItems) => {
      const first = listItems[0];
      const last = listItems[listItems.length - 1];

      const firstCoordinate = [first.lon, first.lat];
      const lastCoordinate = [last.lon, last.lat];

      const from = turfPoint(firstCoordinate);
      const to = turfPoint(lastCoordinate);

      const lineDistance = turfDistance(from, to, { units: 'kilometers' });
      const bearing = turfBearing(from, to) - 90;
      const line = turfLineString([firstCoordinate, lastCoordinate]);

      const listSegments = generateListSegments(lineDistance, line);

      return {
        ...listSegments[positionIndex],
        properties: {
          color: first.color || 'red',
          bearing,
        },
      };
    }) as GeoJSON.Feature[],
  };

  const renderAnimationPoint = (
    <>
      <Source id="animation-point-source" type="geojson" data={geoJsonData}>
        <Layer
          id="animation-icon"
          type="symbol"
          layout={{
            'symbol-placement': 'point',
            'text-field': '▶',
            'text-size': 30,
            'text-keep-upright': false,
            'text-rotation-alignment': 'map',
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-pitch-alignment': 'map',
            'text-rotate': ['get', 'bearing'],
          }}
          paint={{
            'text-color': ['get', 'color'],
          }}
        />
      </Source>
    </>
  );

  return <div>{renderAnimationPoint}</div>;
};

export default DrawingAnimationSymbol;
