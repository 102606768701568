import { Box, Typography, useTheme } from '@mui/material';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { FC } from 'react';

interface ICustomChip {
  children: number;
}

const CustomChip: FC<ICustomChip> = ({ children }) => {
  const theme = useTheme();
  const { isRTL } = useCheckRTL();
  return (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '14px',
        justifyContent: 'center',
        backgroundColor: theme.palette.mode === 'dark' ? '#ffffff29' : '#00000014',
      }}>
      <Typography
        style={{
          fontWeight: '500',
          fontSize: '13px !important',
          marginLeft: isRTL ? 'auto' : 0,
          textAlign: isRTL ? 'left' : 'right',
        }}>
        {children}
      </Typography>
    </Box>
  );
};

export default CustomChip;
