import { Box } from '@mui/material';
import { generateFilteredDataWithId } from 'common/utils/fish-migration';
import { IPopulationCount } from 'interfaces/fish-migration';
import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';
import SelectDateArea from './SelectDateArea';
import SelectIdArea from './SelectIdArea';
import TrackingTable from './TrackingTable';

interface TrackingTabProps {
  populationTableData: IPopulationCount[];
}

const TrackingTab: FC<TrackingTabProps> = ({ populationTableData }) => {
  const {
    populationCount: {
      listSelectedIds: { value: selectedIds },
    },
  } = useAppSelector(fishMigrationSelector);

  const filteredPopulationData = generateFilteredDataWithId(populationTableData, selectedIds);

  return (
    <Box sx={{ pt: '20px' }}>
      <SelectIdArea populationTableData={populationTableData} />
      <SelectDateArea filteredPopulationData={filteredPopulationData} />
      <TrackingTable filteredPopulationData={filteredPopulationData} />
    </Box>
  );
};

export default TrackingTab;
