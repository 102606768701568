import ReportIcon from '@mui/icons-material/Report';
import { Box, Grid, IconButton, InputLabel, TextField, Tooltip, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FILE_TYPE, mapFileLabel } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import SelectFile from 'components/Common/SelectFile';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createLandUse, getRasterDetails, updateAnalyticRelabel } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector, changePageRasterPagination } from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { ErrorsDialog } from './ErrorDialog';

interface ILandUseForm {
  sensorId: any;
  deleteFileGeojson: (id: string, dataType?: string) => Promise<AxiosResponse<any, any>>;
}

export const LandUseForm: FC<ILandUseForm> = ({ sensorId, deleteFileGeojson }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const dispatch = useAppDispatch();
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);
  const { levelId } = useAppSelector(mapViewSelector);
  const theme = useTheme();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery([QUERY_KEY.RASTER_DETAILS, sensorId], () =>
    getRasterDetails(sensorId)
  );

  const createAnalyticMutation = useMutation((data: any) => createLandUse(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      toast.success('Update Land Use Analytic Successful', { toastId: 1 });
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message);
    },
  });

  const updateAnalyticMutation = useMutation((data: any) => updateAnalyticRelabel(data), {
    onSuccess: (res: any) => {
      refetch();
      toast.success('Update Label Successful', { toastId: 1 });
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message || 'Something went wrong!');
    },
  });

  useEffect(() => {
    formRef.current && formRef.current.resetForm();
  }, [sensorId, formRef]);

  const initialFields = useMemo(() => {
    let initParam = {
      polygonFile: undefined,
      lineStringFile: undefined,
      pointFile: undefined,
      reLabel: undefined,
    };
    if (analyticDetails?.data?.uploadHistory) {
      analyticDetails.data.uploadHistory.forEach((_item: any) => {
        initParam = {
          ...initParam,
          [_item.geoJsonType]: _item,
        };
      });
    }
    initParam.reLabel = analyticDetails?.data?.relabel;
    return initParam;
  }, [analyticDetails]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          formData.append('polygonFile', values.POLYGON || '');
          formData.append('lineStringFile', values.LINE || '');
          formData.append('pointFile', values.POINT || '');

          const hasNewFile = Object.values(values).find((item: any) => item?.lastModified);

          if (!!values.reLabel) {
            updateAnalyticMutation.mutate(
              {
                id: sensorId,
                relabel: values.reLabel,
              },
              {
                onSuccess: () => {
                  resetForm();
                },
              }
            );
          }

          if (hasNewFile) {
            createAnalyticMutation.mutate(formData, {
              onSuccess: () => {
                resetForm();
              },
            });
          }
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                <Grid
                  display="flex"
                  alignItems="center"
                  mt={2}
                  justifyContent="space-between"
                  sx={{ marginBottom: '50px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                      {t('trans.relabel')}
                    </InputLabel>
                    <Tooltip title={t('trans.type_in_the_custom_name_for_land_use')}>
                      <IconButton>
                        <ReportIcon sx={{ fontSize: '18px' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <TextField
                    type="text"
                    size="small"
                    name="reLabel"
                    onChange={handleChange}
                    value={values.reLabel}
                    sx={{
                      width: '350px',
                      color: 'white',
                      mt: '7px',
                      '.MuiOutlinedInput-root': {
                        height: '40px',
                        justifyContent: 'end',
                      },
                    }}
                  />
                </Grid>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.POLYGON_FILE], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.geojson$/)}
                    fileSelected={values.POLYGON}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('POLYGON', file);
                    }}
                    fileType={FILE_TYPE.POLYGON_FILE}
                    analysisId={sensorId}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.LINE_STRING_FILE], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.geojson$/)}
                    fileSelected={values.LINE}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('LINE', file);
                    }}
                    fileType={FILE_TYPE.LINE_STRING_FILE}
                    analysisId={sensorId}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.POINT_FILE], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.geojson$/)}
                    fileSelected={values.POINT}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('POINT', file);
                    }}
                    fileType={FILE_TYPE.POINT_FILE}
                    analysisId={sensorId}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'}>
                <ButtonCustom
                  title={t('trans.save')}
                  border="0px"
                  colorLabel="#FFF"
                  sx={{ width: '160px', height: '1.4375em' }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};
