import {
  IUnlinkHarvestTransactionMutateData,
  IWeighbridgeFormInput,
  IWeighbridgeTransactionDistributeData,
  IWeighbridgeTransactionMutateData,
} from 'interfaces/erms';
import { sendDeleteRequest, sendGetRequest, sendGetRequestWithConfig, sendPostRequest } from 'services';

export const getHarvestTableData = async (data: IUnlinkHarvestTransactionMutateData) => {
  const { fromDate, toDate, currentPage, levelIds, linkStatus, perPage } = data;
  const response = await sendGetRequest(`/harvest-analysis/pagination`, {
    page: currentPage,
    perPage,
    sortDate: 'desc',
    paginationVersion: 2,
    levelIds,
    fromDate,
    toDate,
    linkStatus,
  });
  return response?.data;
};

export const getWeighbridgeTransactionTable = async (data: IWeighbridgeTransactionMutateData) => {
  const { fromDate, toDate, currentPage, levelIds, perPage } = data;
  const response = await sendGetRequest(`/weighbridge-analysis/pagination`, {
    page: currentPage,
    perPage,
    sortDate: 'desc',
    paginationVersion: 2,
    levelIds,
    fromDate,
    toDate,
  });
  return response?.data;
};

export const downloadWeighbridgeTransactionTable = async (data: IWeighbridgeTransactionMutateData) => {
  const { fromDate, toDate, currentPage, levelIds, perPage } = data;
  const response = await sendGetRequestWithConfig(
    `/weighbridge-analysis/export`,
    {
      page: currentPage,
      perPage,
      sortDate: 'desc',
      paginationVersion: 2,
      levelIds,
      fromDate,
      toDate,
    },
    {
      responseType: 'arraybuffer',
    }
  );
  return response?.data;
};

export const deleteLinkedHarvest = async (linkId: string) => {
  const response = await sendDeleteRequest(`/harvest-analysis/unlinkWeighbridge/${linkId}`);
  return response.data;
};

export const createWeighbridgeTransaction = async (data: IWeighbridgeFormInput & { analysisId: string }) => {
  const {
    weighDate,
    weighbridgeLocation,
    weighTransaction,
    vehicleDetails,
    driverName,
    lorryWeight,
    harvestWeight,
    netWeight,
    timestamp,
    analysisId,
  } = data;
  return sendPostRequest('/weighbridge-analysis/createManual', {
    weighDate,
    weighbridgeLocation,
    weighTransaction,
    driverName,
    vehicleDetails,
    lorryWeight,
    harvestWeight,
    netWeight,
    timestamp,
    analysisId,
  });
};

export const getWeighbridgeTransactionDistribuitionTable = async (data: IWeighbridgeTransactionDistributeData) => {
  const { levelIds } = data;
  const response = await sendGetRequest(`/harvest-analysis/harvestLinkChart`, {
    levelIds,
  });
  return response?.data;
};
