import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { FC, ReactNode, useState } from 'react';

interface CommonCollapseProps {
  label: string;
  children: ReactNode;
}

const CommonCollapse: FC<CommonCollapseProps> = ({ label, children }) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(true);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();

  return (
    <Box className={classes2.boxLayout} style={{ borderRadius: '20px', marginBottom: '20px' }}>
      <Box>
        <Button
          endIcon={
            isOpenCollapse ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : (
              <ChevronRightIcon style={{ fontWeight: 'bold' }} />
            )
          }
          onClick={() => setIsOpenCollapse(!isOpenCollapse)}
          classes={{ root: classes.buttonTextStyle }}
          style={{ width: '100%' }}>
          <Typography
            className={classes.buttonTextStyle}
            style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '14px !important' }}>
            {label}
          </Typography>
        </Button>
      </Box>
      <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit sx={{ px: '16px' }}>
        {children}
      </Collapse>
    </Box>
  );
};

export default CommonCollapse;
