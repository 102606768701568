import PublicIcon from '@mui/icons-material/Public';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TableViewIcon from '@mui/icons-material/TableView';
import { Box, Button, Paper, SvgIcon, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { ITableHeaders, TSortOrder } from 'common/defines/constants';
import { extractFishSpeciesName } from 'common/utils/fish-migration';
import { generateKmlForPoint, KmlProperty } from 'common/utils/generate-kml';
import CommonModal from 'components/Common/CommonModal';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import { ITrackingTableData } from 'interfaces/fish-migration';
import moment from 'moment';
import { FC, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { CustomTableBodyCell, CustomTableHeaderCell } from '../components/CustomTableCell';

enum TableKeyEnum {
  ID = 'ID',
  SPECIES = 'SPECIES',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  DURATION = 'DURATION',
  DISTANCE = 'DISTANCE',
}

const initialTableHeaders = [
  { key: TableKeyEnum.ID, label: 'trans.id', sortOrder: null },
  { key: TableKeyEnum.SPECIES, label: 'trans.species', sortOrder: null },
  { key: TableKeyEnum.START_DATE, label: 'trans.start_date', sortOrder: null },
  { key: TableKeyEnum.END_DATE, label: 'trans.end_date', sortOrder: null },
  { key: TableKeyEnum.DURATION, label: 'trans.duration_days', sortOrder: null },
  { key: TableKeyEnum.DISTANCE, label: 'trans.distance_traveled', sortOrder: null },
];

interface TrackingTableDetailModalProps {
  handleClose: () => void;
  trackingTableData: ITrackingTableData[];
}

const TrackingTableDetailModal: FC<TrackingTableDetailModalProps> = ({ handleClose, trackingTableData }) => {
  const [tableHeaders, setTableHeaders] = useState<ITableHeaders<TableKeyEnum>[]>(initialTableHeaders);
  const [sortInfo, setSortInfo] = useState<{ key: TableKeyEnum; sortOrder: TSortOrder }>({
    key: TableKeyEnum.ID,
    sortOrder: 'ASC',
  });

  const { t } = useTranslation();

  const renderSortIcon = (sortOrder: TSortOrder) => {
    switch (sortOrder) {
      case 'ASC':
        return DescIcon;
      case 'DESC':
        return AscIcon;
      default:
        return SwapVertIcon;
    }
  };

  const handleSortTable = (key: TableKeyEnum, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newTableHeaders = tableHeaders.map((header) =>
      header.key === key ? { ...header, sortOrder: newSortOrder } : { ...header, sortOrder: null }
    );
    setTableHeaders(newTableHeaders);
  };

  const sortFunction = (first: ITrackingTableData, second: ITrackingTableData) => {
    const { key, sortOrder } = sortInfo;
    if (key === TableKeyEnum.ID) {
      return sortOrder === 'ASC' ? first.tagId - second.tagId : second.tagId - first.tagId;
    } else if (key === TableKeyEnum.SPECIES) {
      return sortOrder === 'ASC'
        ? first.species.localeCompare(second.species)
        : second.species.localeCompare(first.species);
    } else if (key === TableKeyEnum.START_DATE) {
      return sortOrder === 'ASC'
        ? moment(first.startPoint.date).diff(second.startPoint.date)
        : moment(second.startPoint.date).diff(first.startPoint.date);
    } else if (key === TableKeyEnum.END_DATE) {
      return sortOrder === 'ASC'
        ? moment(first.endPoint.date).diff(second.endPoint.date)
        : moment(second.endPoint.date).diff(first.endPoint.date);
    } else if (key === TableKeyEnum.DURATION) {
      return sortOrder === 'ASC' ? first.duration - second.duration : second.duration - first.duration;
    } else if (key === TableKeyEnum.DISTANCE) {
      return sortOrder === 'ASC' ? first.distance - second.distance : second.distance - first.distance;
    }

    return 0;
  };

  const sortedTableData = [...trackingTableData].sort((a, b) => sortFunction(a, b));

  const handleClickKmlIcon = () => {
    const name = `wildlife_count`;

    const lineStringListData = sortedTableData.map((item) => {
      const { tagId, startPoint, endPoint } = item;
      return {
        name: `wildlife_count_${moment(startPoint.date).format(DATE_VISUALIZE_FORMAT)} - ${moment(endPoint.date).format(
          DATE_VISUALIZE_FORMAT
        )}`,
        geometries: [startPoint, endPoint].map((item) => ({ longitude: item.lon, latitude: item.lat })),
        tagId,
        startDate: `${moment(startPoint.date).format(DATE_VISUALIZE_FORMAT)}`,
        endDate: `${moment(endPoint.date).format(DATE_VISUALIZE_FORMAT)}`,
        duration: item.duration,
        distance: item.distance,
      };
    });

    const pointListData = sortedTableData
      .map((item) => [
        { ...item.startPoint, isStartPoint: true },
        { ...item.endPoint, isStartPoint: false },
      ])
      .flat()
      .map((item) => {
        const { tagId, power, lon, lat, image, date, isStartPoint } = item;

        let convertedItem: KmlProperty = {
          name: `wildlife_count_${moment(date).format(DATE_VISUALIZE_FORMAT)}`,
          geometries: [{ longitude: lon, latitude: lat }],
          tagId,
          species: extractFishSpeciesName(image),
          power,
        };

        if (isStartPoint) {
          convertedItem = { ...convertedItem, startDate: `${moment(date).format(DATE_VISUALIZE_FORMAT)}` };
        } else {
          convertedItem = { ...convertedItem, endDate: `${moment(date).format(DATE_VISUALIZE_FORMAT)}` };
        }

        return convertedItem;
      });

    const kmlContent = generateKmlForPoint({
      name,
      properties: [...lineStringListData, ...pointListData],
    });
    const blob = new Blob([kmlContent], { type: 'application/vnd.google-earth.kml+xml' });
    saveAs(blob, `wildlife_count.kml`);
  };

  const handleClickCsvIcon = () => {
    const csvBody = sortedTableData.map((item) => {
      const { tagId, species, startPoint, endPoint } = item;

      const startDate = moment(startPoint.date).format(DATE_VISUALIZE_FORMAT);
      const endDate = moment(endPoint.date).format(DATE_VISUALIZE_FORMAT);

      return [tagId, species, startDate, startPoint.lon, startPoint.lat, endDate, endPoint.lon, endPoint.lat];
    });

    const csvContent = [
      ['Tag ID', 'Species', 'Start Date', 'Longitude', 'Latitude', 'End Date', 'Longitude', 'Latitude'],
      ...csvBody,
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'tracking_wildlife_count.csv');
  };

  return (
    <CommonModal
      isShow={true}
      handleClose={handleClose}
      closeIconColor="none"
      style={{ height: 'auto', width: '800px', p: '32px' }}>
      <Box>
        <Box display="flex" gap="8px">
          <Button variant="contained" size="small" onClick={() => handleClickKmlIcon()}>
            <PublicIcon sx={{ fontSize: '16px', cursor: 'pointer', mr: '4px' }} />
            Download kml
          </Button>
          <Button variant="contained" size="small" onClick={() => handleClickCsvIcon()}>
            <TableViewIcon sx={{ fontSize: '16px', cursor: 'pointer', mr: '4px' }} />
            Download csv
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ mt: '16px', position: 'relative' }}>
          <Scrollbars autoHeight autoHide autoHeightMax={'700px'}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((item) => (
                    <CustomTableHeaderCell key={item.key} sx={{ width: '120px' }}>
                      <Box display="flex" alignItems="center">
                        {t(item.label)}
                        <SvgIcon
                          component={renderSortIcon(item.sortOrder)}
                          inheritViewBox
                          sx={{ fontSize: '14px', cursor: 'pointer', ml: '4px' }}
                          onClick={() => handleSortTable(item.key, item.sortOrder)}
                        />
                      </Box>
                    </CustomTableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTableData.map((row, index) => {
                  const { tagId, species, startPoint, endPoint, duration, distance } = row;

                  const startDate = moment(startPoint.date).format(DATE_VISUALIZE_FORMAT);
                  const endDate = moment(endPoint.date).format(DATE_VISUALIZE_FORMAT);

                  return (
                    <TableRow key={index}>
                      <CustomTableBodyCell>{tagId}</CustomTableBodyCell>
                      <CustomTableBodyCell>{species}</CustomTableBodyCell>
                      <CustomTableBodyCell>{startDate}</CustomTableBodyCell>
                      <CustomTableBodyCell>{endDate}</CustomTableBodyCell>
                      <CustomTableBodyCell sx={{ textAlign: 'center' }}>{duration}</CustomTableBodyCell>
                      <CustomTableBodyCell sx={{ textAlign: 'center' }}>{distance}</CustomTableBodyCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Scrollbars>
        </TableContainer>
      </Box>
    </CommonModal>
  );
};

export default TrackingTableDetailModal;
