import { bearing as turfBearing, distance as turfDistance, point as turfPoint } from '@turf/turf';
import { generateGroupingDataWithPeriod, groupPopulationCountWithTagId } from 'common/utils/fish-migration';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import DrawingAnimationSymbol from './DrawingAnimationSymbol';

export const QUANTITY_ANIMATION_POINTS = 200;

interface DrawingLinesProps {
  filteredDataWithDate: IPopulationCount[];
}

const DrawingLines: FC<DrawingLinesProps> = ({ filteredDataWithDate }) => {
  const populationCountGroupedWithTagId = groupPopulationCountWithTagId(filteredDataWithDate);
  const groupDataWithPeriod = generateGroupingDataWithPeriod(populationCountGroupedWithTagId);

  const {
    populationCount: { animationStatus },
  } = useAppSelector(fishMigrationSelector);

  const { levelId } = useAppSelector(mapViewSelector);
  const isShowAnimation = animationStatus.find((item) => item.levelId === levelId)?.isAnimate ?? false;

  const geoJsonData: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: groupDataWithPeriod?.map((listItems) => {
      const first = listItems[0];
      const last = listItems[listItems.length - 1];

      const firstCoordinate = [first.lon, first.lat];
      const lastCoordinate = [last.lon, last.lat];

      const from = turfPoint(firstCoordinate);
      const to = turfPoint(lastCoordinate);

      const distance = turfDistance(from, to, { units: 'kilometers' });

      const duration = moment(last.date).diff(first.date, 'days');

      const bearing = turfBearing(turfPoint(firstCoordinate), turfPoint(lastCoordinate));

      const offsetAll = [10, 10];
      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [firstCoordinate, lastCoordinate],
        },
        properties: {
          color: first.color || 'red',
          distance,
          duration,
          bearing,
          offsetAll,
        },
      };
    }) as GeoJSON.Feature[],
  };

  const renderArrowSymbol = !isShowAnimation && (
    <Layer
      id="arrow-layer"
      type="symbol"
      layout={{
        'symbol-placement': 'line-center',
        'symbol-spacing': 100, // Distance between arrows in pixels
        'text-field': '➧', // Unicode arrow character
        'text-size': 24,
        'text-keep-upright': false,
        'text-rotation-alignment': 'map',
        'text-allow-overlap': true,
        'text-ignore-placement': true,
        'text-pitch-alignment': 'map',
      }}
      paint={{
        'text-color': ['get', 'color'],
      }}
    />
  );

  return (
    <div>
      <Source id="line-source" type="geojson" data={geoJsonData}>
        <Layer
          id="population-tracking-lines"
          type="line"
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': ['get', 'color'],
            'line-width': 3,
          }}
        />
        {!isShowAnimation && renderArrowSymbol}
      </Source>
      {isShowAnimation && <DrawingAnimationSymbol groupDataWithPeriod={groupDataWithPeriod} />}
    </div>
  );
};

export default DrawingLines;
