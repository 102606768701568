import ReportIcon from '@mui/icons-material/Report';
import { Box, Grid, IconButton, InputLabel, TextField, Tooltip, useTheme } from '@mui/material';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getRasterDetails, updateAnalyticRelabel } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector, changePageRasterPagination } from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { ErrorsDialog } from './ErrorDialog';

interface CommonFieldFormProps {
  sensorId: string;
}

const AquaticSpeciesTrackingForm: FC<CommonFieldFormProps> = ({ sensorId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);
  const { levelId } = useAppSelector(mapViewSelector);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery(
    [QUERY_KEY.RASTER_DETAILS, sensorId],
    () => getRasterDetails(sensorId),
    {
      enabled: !!sensorId,
    }
  );

  const updateAnalyticMutation = useMutation((data: any) => updateAnalyticRelabel(data), {
    onSuccess: (res: any) => {
      refetch();
      toast.success('Update Label Successful', { toastId: 1 });
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message || 'Something went wrong!');
    },
  });

  const initialFields = useMemo(() => {
    let initParam: any = { relabel: '' };
    if (analyticDetails?.data?.relabel) {
      initParam.reLabel = analyticDetails.data.relabel;
    }

    return initParam;
  }, [analyticDetails]);

  const renderRelabelPart = (values: any, handleChange: any) => {
    return (
      <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between" sx={{ marginBottom: '50px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
            {t('trans.relabel')}
          </InputLabel>
          <Tooltip title={t('trans.type_in_the_custom_name_for_the_field_analytics')}>
            <IconButton>
              <ReportIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <TextField
          type="text"
          size="small"
          name="reLabel"
          onChange={handleChange}
          value={values.reLabel}
          sx={{
            width: '350px',
            mt: '7px',
            '.MuiOutlinedInput-root': {
              height: '40px',
              justifyContent: 'end',
            },
          }}
        />
      </Grid>
    );
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          if (!!values.reLabel) {
            updateAnalyticMutation.mutate(
              {
                id: sensorId,
                relabel: values.reLabel,
              },
              {
                onSuccess: () => {
                  resetForm();
                },
              }
            );
          }
        }}>
        {({ values, handleSubmit, submitForm, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>{renderRelabelPart(values, handleChange)}</Grid>
              <Grid marginLeft="auto">
                <ButtonCustom
                  border="0px"
                  colorLabel="#fff"
                  sx={{
                    width: '160px',
                    height: '1.4375em',
                  }}
                  title={t('trans.save')}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={updateAnalyticMutation.isLoading}
                  loading={updateAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

export default AquaticSpeciesTrackingForm;
