import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import { IPopulationCount } from 'interfaces/fish-migration';
import { useQuery } from 'react-query';
import { getAllPopulationCountAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { sendGetDataAnalysisByLevelIdDate } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const useGetDataPopulationCount = () => {
  const { levelId, dateAnalyticSelected } = useAppSelector(mapViewSelector);

  const { data: resListAnalytic } = useQuery(
    [QUERY_KEY.DATA_ANALYSIS, levelId, dateAnalyticSelected],
    () => sendGetDataAnalysisByLevelIdDate(levelId || '', dateAnalyticSelected || ''),
    {
      enabled: !!dateAnalyticSelected && !!levelId,
    }
  );

  const listAnalyticData = resListAnalytic?.data;

  const populationCountAnalyticId =
    listAnalyticData?.find((item: any) => item.name === TYPE_ANALYTICS_MAP_VIEW.POPULATION_COUNT)?._id || '';

  const { data: resPopulationCount } = useQuery(
    [QUERY_KEY.POPULATION_COUNT_ANALYTIC, populationCountAnalyticId],
    () => getAllPopulationCountAnalytic(populationCountAnalyticId || ''),
    {
      enabled: !!populationCountAnalyticId,
    }
  );

  const populationCountData: IPopulationCount[] = resPopulationCount?.data;

  return { populationCountData };
};

export default useGetDataPopulationCount;
