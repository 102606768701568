import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { RasterLayerDTO } from 'common/defines/analytic';
import { LABEL_DATA_RASTERS, LayerTypeEnum, listRasterLayers, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import VectorCollapse from 'components/MapView/RightBar/AnalyticsTab/Layer/SwitchRaster/VectorCollapse';
import { QUERY_KEY } from 'constants/constants';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { filter, get, isEmpty, uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getRasterDetails, getRasterLayer, getRasterLegend } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  mapViewSelector,
  selectRasterLayer,
  setLayerTypeCurrentList,
  unSelectRasterLayer,
} from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import RasterCollapse from './RasterCollapse';

interface IRasterLegendData {
  folderPath: string;
  layerType: LayerTypeEnum;
  s3Path: string;
}

const TypeRasterConditons = {
  [TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE]: [LayerTypeEnum.VIGOR_FIELD, LayerTypeEnum.VIGOR_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE]: [LayerTypeEnum.CHLOROPHYLL_FIELD, LayerTypeEnum.CHLOROPHYLL_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE]: [LayerTypeEnum.STRESS_FIELD, LayerTypeEnum.STRESS_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE]: [LayerTypeEnum.WATER_UPTAKE_FIELD, LayerTypeEnum.WATER_UPTAKE_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE]: [LayerTypeEnum.PLANT_HEALTH_FIELD, LayerTypeEnum.PLANT_HEALTH_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT]: [
    LayerTypeEnum.SOIL_MOISTURE_CONTENT_FIELD,
    LayerTypeEnum.SOIL_MOISTURE_CONTENT_POLYGON,
  ],

  [TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD]: [LayerTypeEnum.PLANT_HEALTH_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD]: [LayerTypeEnum.VIGOR_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD]: [LayerTypeEnum.CHLOROPHYLL_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD]: [LayerTypeEnum.STRESS_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD]: [LayerTypeEnum.WATER_UPTAKE_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD]: [LayerTypeEnum.SOIL_WATER_CONTENT_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD]: [LayerTypeEnum.SOIL_EROSION_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD]: [LayerTypeEnum.LEAF_PHENOLOGY_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD]: [LayerTypeEnum.ELEVATION_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD]: [LayerTypeEnum.SLOPE_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.CUSTOM_FIELD]: [LayerTypeEnum.CUSTOM_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT]: [LayerTypeEnum.VEGETATION_ENCROACHMENT],
  [TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT]: [LayerTypeEnum.CANOPY_HEIGHT_MODEL],
  [TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION]: [
    LayerTypeEnum.COVER_20,
    LayerTypeEnum.ABOVEGROUND_CARBON_DENSITY,
    LayerTypeEnum.CO2_SEQUESTRATION,
  ],
};

export const SwitchRaster = ({ analyticType, isShowInlineBlock }: any) => {
  const [layerByRasterVector, setLayerByRasterVector] = useState(false);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();
  const dispatch = useAppDispatch();
  const { layerTypeList, dateAnalyticSelected, levelId, analyticId } = useAppSelector(mapViewSelector);
  const { analyticReLabel, analyticName } = useAppSelector(rightBarSelector);
  const { t } = useTranslation();
  const { isRTL } = useCheckRTL();

  const listAnalyticsWithImage = [TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION];

  const { data } = useQuery<{ data: RasterLayerDTO[] }>(
    [QUERY_KEY.RASTER_LAYER, dateAnalyticSelected, levelId],
    () => getRasterLayer(levelId || '', dateAnalyticSelected || ''),
    {
      enabled: !isEmpty(levelId) && !isEmpty(dateAnalyticSelected),
    }
  );

  const { data: resLegend } = useQuery([QUERY_KEY.GET_RASTER_LEGEND], () => getRasterLegend(analyticId!), {
    enabled: !isEmpty(analyticId),
  });

  const dataLegend = resLegend?.data as IRasterLegendData[];

  const { data: rasterDetail } = useQuery([QUERY_KEY.RASTER_DETAILS], () => getRasterDetails(analyticId!), {
    enabled: !!analyticId && !!data?.data,
  });

  const uniqueRasterLayer = useMemo(() => {
    return uniqBy(layerTypeList, '_id');
  }, [layerTypeList]);

  const filterData = (dataRasterList: any, conditions: any) => {
    return filter(dataRasterList, (item) => conditions.includes(item?.layerType));
  };

  const rasterList = useMemo(() => {
    const rasterListFromDetail = rasterDetail?.data?.uploadHistory;

    const convertedRasterList: any[] = Array.isArray(rasterListFromDetail)
      ? rasterListFromDetail.map((item) => ({
        ...item,
        analysisId: {
          _id: item.analysisId,
          name: item.name,
        },
      }))
      : [];
    const dataRasterList = data?.data;

    if (dataRasterList && convertedRasterList) {
      const rasterRGB = dataRasterList?.find((i: RasterLayerDTO) => i.layerType === LayerTypeEnum.RGB_ORTHOIMAGE);
      const rasterLayerList = dataRasterList.filter((item) => {
        return listRasterLayers.includes(item.layerType as LayerTypeEnum) || item.isCommon;
      });
      const fullRasterList = [...rasterLayerList, ...convertedRasterList];

      dispatch(setLayerTypeCurrentList(fullRasterList));
      if (rasterRGB) {
        dispatch(selectRasterLayer([rasterRGB, ...uniqueRasterLayer]));
      } else {
        dispatch(selectRasterLayer([]));
      }
      const rasterData = () => {
        switch (analyticType) {
          case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT:
          case TYPE_ANALYTICS_MAP_VIEW.CUSTOM_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT:
          case TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT:
          case TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION:
            return filterData(fullRasterList, get(TypeRasterConditons, analyticType));
          default:
            return [];
        }
      };
      return rasterData();
    }
    dispatch(selectRasterLayer([]));
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, analyticType, analyticId]);

  const getLabelDataRaster = (layerType: string) => {
    if (Object.values(LayerTypeEnum).includes(layerType as LayerTypeEnum)) {
      return LABEL_DATA_RASTERS[layerType as LayerTypeEnum];
    }
    return layerType;
  };

  const getNameRaster = useCallback(
    (_item: RasterLayerDTO) => {
      const translatedText = t(convertTextToMultiLanguage(getLabelDataRaster(_item.layerType), 'label_raster'));
      const translatedRgb = t(convertTextToMultiLanguage(_item.analysisId.name, 'mapView'));
      if (_item.layerType === LayerTypeEnum.RGB_ORTHOIMAGE) {
        return `${translatedText} - ${translatedRgb}`;
      }
      if (_item.layerType === LayerTypeEnum.CUSTOM_FIELD) {
        return analyticReLabel || translatedText;
      }
      return translatedText;
    },
    [analyticReLabel, t]
  );

  const listRasterData = useMemo(() => {
    const dataRasterList = data?.data;
    if (!dataRasterList) return;
    return dataRasterList.filter(
      (item) => listRasterLayers.includes(item?.layerType as LayerTypeEnum) || item.isCommon
    );
  }, [data?.data]);

  const handleChangeRaster = (item: any, isEnabled: boolean) => {
    rasterList.forEach((item) => {
      dispatch(unSelectRasterLayer(item));
    });
    !isEnabled && dispatch(selectRasterLayer(item));
  };

  const handleSwitchCommonRaster = (item: RasterLayerDTO) => {
    if (layerTypeList?.find((ele) => ele.layerType === item.layerType)) {
      dispatch(selectRasterLayer(layerTypeList.filter((ele) => ele.layerType !== item.layerType)));
    } else {
      if (item.orderNumber) {
        dispatch(selectRasterLayer([...layerTypeList, item]));
      } else {
        const customRasterList = layerTypeList.filter((item) => item.orderNumber);
        dispatch(selectRasterLayer([...customRasterList, item]));
      }
    }
  };

  const renderLegendImage = (layerType: LayerTypeEnum | string) => {
    const rasterImageLayer = `${layerType}_LEGEND`;
    const imagePath = dataLegend.find((item) => item.layerType === rasterImageLayer)?.folderPath;
    return <img src={imagePath} alt="legend" style={{ width: '65px', height: 'auto' }} />;
  };

  return (
    <>
      {/* Orthoimage - RGB Multispectral */}
      {!isShowInlineBlock ? (
        <Box
          className={classes2.boxLayout}
          style={{
            padding: '20px 20px',
            borderRadius: '20px',
            marginBottom: '20px',
            direction: isRTL ? 'rtl' : 'ltr',
          }}>
          <Button
            endIcon={
              layerByRasterVector ? (
                <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
              ) : (
                <ChevronRightIcon style={{ fontWeight: 'bold' }} />
              )
            }
            onClick={() => setLayerByRasterVector(!layerByRasterVector)}
            classes={{ root: classes.buttonTextStyle }}
            style={{ width: '100%' }}>
            <Typography
              className={classes.buttonTextStyle}
              style={{
                marginRight: isRTL ? 0 : 'auto',
                marginLeft: isRTL ? 'auto' : 0,
                fontWeight: 'bold',
                fontSize: '14px !important',
                textAlign: isRTL ? 'right' : 'left',
              }}>
              {t('mapView.common_rasters_and_vectors')}
            </Typography>
          </Button>
          <Collapse in={layerByRasterVector} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <RasterCollapse
              listRasterData={listRasterData}
              handleSwitchCommonRaster={handleSwitchCommonRaster}
              getNameRaster={getNameRaster}
            />
            <VectorCollapse />
          </Collapse>
        </Box>
      ) : (
        <Grid container>
          {rasterList &&
            rasterList
              .sort((a: any, b: any) => a?.layerType.localeCompare(b?.layerType))
              .map((_item: RasterLayerDTO) => {
                const isCheckSwitch = layerTypeList.some((i) => i._id === _item._id);
                return (
                  _item.layerType && (
                    <Grid item xs={12} key={_item._id} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <FormControlLabel
                        labelPlacement={isRTL ? 'start' : 'end'}
                        control={
                          <SwitchCustom
                            checked={isCheckSwitch}
                            onClick={(e: any) => {
                              handleChangeRaster(_item, isCheckSwitch);
                            }}
                          />
                        }
                        label={<Typography classes={{ root: classes.selectText }}>{getNameRaster(_item)}</Typography>}
                      />
                      {isCheckSwitch &&
                        listAnalyticsWithImage.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW) &&
                        renderLegendImage(_item.layerType)}
                    </Grid>
                  )
                );
              })}
        </Grid>
      )}
    </>
  );
};
