import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IMetadata } from 'common/defines/clients';
import { ExcelContentType, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import ExcelButton from 'components/Common/ExcelButton';
import PaginationCommon from 'components/Common/PaginationCommon';
import { QUERY_KEY } from 'constants/constants';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import { IOption, ITreeNodeCheckbox, IWeighbridgeTransactionTableDisplay } from 'interfaces/erms';
import { get } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { getRasterListByPagination } from 'services/analytics/apiAnalyticsConfig.services';
import { downloadWeighbridgeTransactionTable, getWeighbridgeTransactionTable } from 'services/erms';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeTriggerCallTransactionTable, ermsSelector } from 'store/slices/ermsSlice';
import CommonContainer from '../components/CommonContainer';
import CustomTableCell from '../components/CustomTableCell';
import AddTransaction from './AddTransaction';

interface WeighbridgeTransactionTableProps {
  treeData: ITreeNodeCheckbox[];
  setListTagOptions: (arg: IOption[]) => void;
}

const WeighbridgeTransactionTable: FC<WeighbridgeTransactionTableProps> = ({ treeData, setListTagOptions }) => {
  const [weighbridgeTransactionTableData, setWeighbridgeTransactionTableData] = useState<
    IWeighbridgeTransactionTableDisplay[]
  >([]);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [metadata, setMetadata] = useState<IMetadata>();
  const [sensorId, setSensorId] = useState<string>('');

  const { filterDate, trigger } = useAppSelector(ermsSelector);
  const dispatch = useAppDispatch();

  const listHighestLevelIds = treeData.map((item) => (item.isCheck ? item._id : '')).filter((item) => item);

  // this query to get sensorId
  useQuery(
    [QUERY_KEY.RASTER_PAGINATION, listHighestLevelIds[0]],
    () => getRasterListByPagination(1, 50, null, null, listHighestLevelIds[0] || null),
    {
      enabled: !!listHighestLevelIds.length,
      onSuccess: (res) => {
        const analysisList = get(res, 'data.docs[0].analysis');
        const foundSensorId =
          analysisList.find((item: any) => item.name === TYPE_ANALYTICS_MAP_VIEW.WEIGHBRIDGE_ANALYSIS)?._id || '';
        setSensorId(foundSensorId);
      },
    }
  );

  const weighbridgeTransactionRequest = {
    fromDate: moment(filterDate.startDate).utc(true).toISOString(),
    toDate: moment(filterDate.endDate).utc(true).toISOString(),
    currentPage: currentPage,
    levelIds: listHighestLevelIds,
    perPage: 10,
  };

  const { refetch: refetchWeighbridgeTransaction } = useQuery(
    [QUERY_KEY.GET_WEIGHBRIDGE_TRANSACTION_TABLE, currentPage],
    () => getWeighbridgeTransactionTable(weighbridgeTransactionRequest),
    {
      enabled: !!listHighestLevelIds.length,
      retry: 0,
      onSuccess(res) {
        setMetadata(res?.metadata);

        const data = res?.data as IWeighbridgeTransactionTableDisplay[];
        const displayData = data.map((row) => {
          const { vehicleDetails, weighTransaction, weighDate, timestamp } = row;
          const displayWeighDate = moment(weighDate).format(DATE_VISUALIZE_FORMAT);
          const [h, m, s] = timestamp.split(':');
          const displayTimestamp = `${h}:${m}:${s ?? '00'}`;
          return { ...row, tags: `${vehicleDetails}, ${weighTransaction}, ${displayWeighDate}, ${displayTimestamp}` };
        });
        setWeighbridgeTransactionTableData(displayData);

        const tagOptions = displayData.map((row) => ({
          value: row._id,
          label: row.tags,
        }));
        setListTagOptions(tagOptions);
      },
      onError() {
        setWeighbridgeTransactionTableData([]);
        setMetadata(undefined);
        setListTagOptions([]);
      },
    }
  );

  useEffect(() => {
    const { isCallWeighbridgeTransactionTable } = trigger;
    if (isCallWeighbridgeTransactionTable) {
      refetchWeighbridgeTransaction();
      dispatch(changeTriggerCallTransactionTable({ isCallWeighbridgeTransactionTable: false }));
    }
  }, [dispatch, refetchWeighbridgeTransaction, trigger]);

  const handleDownloadExcelFileMutation = useMutation(
    () => downloadWeighbridgeTransactionTable(weighbridgeTransactionRequest),
    {
      onSuccess: (data) => {
        const blob = new Blob([data], {
          type: ExcelContentType,
        });

        const fileName = `weighbridge_table_${filterDate.startDate}_${filterDate.endDate}.xlsx`;
        saveAs(blob, fileName);
      },
    }
  );

  const handleDownloadExcelFile = () => {
    handleDownloadExcelFileMutation.mutate();
  };

  return (
    <CommonContainer style={{ px: 3, py: 1 }}>
      <Box sx={{ mb: 2, position: 'relative' }}>
        <Box sx={{ position: 'absolute', left: 0 }}>
          <ExcelButton onClick={handleDownloadExcelFile} isLoading={handleDownloadExcelFileMutation.isLoading} />
        </Box>
        <Typography component="h1" fontSize="20px" sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 500 }}>
          {t('erms.weighbridge_transaction')}
        </Typography>
        <AddTransaction sensorId={sensorId} />
      </Box>

      <TableContainer component={Paper}>
        <Scrollbars autoHeight autoHeightMax="500px" autoHide>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <CustomTableCell>{t('erms.weigh_date')}</CustomTableCell>
                <CustomTableCell>{t('erms.weighbridge_location')}</CustomTableCell>
                <CustomTableCell>{t('erms.transaction_number')}</CustomTableCell>
                <CustomTableCell>{t('erms.driver_name')}</CustomTableCell>
                <CustomTableCell>{t('erms.vehicle_number')}</CustomTableCell>
                <CustomTableCell>{t('erms.lorry_weight_tonne')}</CustomTableCell>
                <CustomTableCell>{t('erms.harvest_weight_tonne')}</CustomTableCell>
                <CustomTableCell>{t('erms.net_weight_tonne')}</CustomTableCell>
                <CustomTableCell>{t('erms.tags')}</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {weighbridgeTransactionTableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ minWidth: '110px' }}>
                    {moment(row.weighDate).format(DATE_VISUALIZE_FORMAT)}
                  </TableCell>
                  <TableCell>{row.weighbridgeLocation}</TableCell>
                  <TableCell>{row.weighTransaction}</TableCell>
                  <TableCell>{row.driverName}</TableCell>
                  <TableCell>{row.vehicleDetails}</TableCell>
                  <TableCell>{row.lorryWeight}</TableCell>
                  <TableCell>{row.harvestWeight}</TableCell>
                  <TableCell>{row.netWeight}</TableCell>
                  <TableCell>
                    <Chip label={row.tags} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbars>
      </TableContainer>

      <Box sx={{ mt: '16px', display: 'flex', justifyContent: 'flex-end' }}>
        <PaginationCommon
          currentPage={currentPage}
          totalPage={metadata?.pageCount ?? 1}
          onChangePage={(value) => {
            setCurrentPage(value);
          }}
        />
      </Box>
    </CommonContainer>
  );
};

export default WeighbridgeTransactionTable;
