import { Box, FormControlLabel, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { OPTION_ALL } from 'common/defines/constants';
import { generateFilteredFlightCommissionDates } from 'common/utils/fish-migration';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { DATE_STANDARD_FORMAT, DATE_VISUALIZE_FORMAT } from 'constants/date';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeFlightMissionDates,
  changeHeatmapStatus,
  fishMigrationSelector,
} from 'store/slices/map-view/fishMigrationAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import PopulationTable from './PopulationTable';

interface PopulationTabProps {
  populationTableData: IPopulationCount[];
}

const PopulationTab: FC<PopulationTabProps> = ({ populationTableData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { levelId } = useAppSelector(mapViewSelector);

  const {
    populationCount: {
      flightMissionDates: { label: flightMissionDates },
      heatmapStatus,
    },
  } = useAppSelector(fishMigrationSelector);

  const sortedUniqueListDate = useMemo(() => {
    const listDate = populationTableData.map((item) => moment(item.date).format(DATE_STANDARD_FORMAT));
    const sortedListDate = [...listDate].sort((a, b) => {
      return moment(a, DATE_STANDARD_FORMAT).diff(moment(b, DATE_STANDARD_FORMAT));
    });
    return [...new Set(sortedListDate)];
  }, [populationTableData]);

  const listOptions = sortedUniqueListDate.map((item) => ({
    value: moment(item).format(DATE_STANDARD_FORMAT),
    label: moment(item).format(DATE_VISUALIZE_FORMAT),
  }));
  const finalListOptions = [{ value: OPTION_ALL, label: t('trans.all') }, ...listOptions];

  const isShowHeatmap = heatmapStatus.find((item) => item.levelId === levelId)?.isShowHeatmap ?? false;

  // initial value of flight dates
  useEffect(() => {
    dispatch(
      changeFlightMissionDates({
        label: [OPTION_ALL],
        value: sortedUniqueListDate,
      })
    );
  }, [dispatch, sortedUniqueListDate]);

  const handleChange = (event: SelectChangeEvent<typeof flightMissionDates>) => {
    const value = event.target.value;
    if (value[value.length - 1] === OPTION_ALL) {
      dispatch(
        changeFlightMissionDates({
          label: [OPTION_ALL],
          value: sortedUniqueListDate,
        })
      );
    } else {
      const selectedDates = typeof value === 'string' ? value.split(',') : value.filter((item) => item !== OPTION_ALL);
      dispatch(
        changeFlightMissionDates({
          label: selectedDates,
          value: selectedDates,
        })
      );
    }

    if (value?.length === 0) {
      dispatch(
        changeFlightMissionDates({
          label: [OPTION_ALL],
          value: sortedUniqueListDate,
        })
      );
    }
  };

  const filteredPopulationTableData = generateFilteredFlightCommissionDates(populationTableData, flightMissionDates);

  const handleClickSwitch = (e: any) => {
    const value = e.target.checked;
    if (!levelId) return;
    let newAnimationStatus = [];

    if (value) {
      newAnimationStatus = [...heatmapStatus, { levelId, isShowHeatmap: true }];
      dispatch(changeHeatmapStatus(newAnimationStatus));
    } else {
      newAnimationStatus = heatmapStatus
        .filter((item) => item.levelId !== levelId)
        .filter((item) => item.isShowHeatmap);
    }
    dispatch(changeHeatmapStatus(newAnimationStatus));
  };

  return (
    <Box sx={{ pt: '20px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Grid container>
        <Grid item xs={3}>
          <Typography>{t('trans.fight_mission_date')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Select
            id="select-date"
            value={flightMissionDates}
            onChange={handleChange}
            size="small"
            sx={{ width: '100%' }}
            multiple>
            {finalListOptions.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>

      <Box>
        <FormControlLabel
          sx={{ ml: 0.5 }}
          control={<SwitchCustom checked={isShowHeatmap} onChange={handleClickSwitch} />}
          label={t('mapView.heatmap')}
        />
      </Box>

      <Box>
        <PopulationTable populationTableData={filteredPopulationTableData} />
      </Box>
    </Box>
  );
};

export default PopulationTab;
