import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeIsShowCommentViewModal,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';

interface CommentViewProps {
  id: string;
  commentCount: number;
}

const CommentView: FC<CommentViewProps> = ({ id, commentCount }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    commentViewModal: { id: selectedId },
  } = useAppSelector(photoIntelligenceSelector);

  return (
    <Box onClick={() => dispatch(changeIsShowCommentViewModal({ id: id, isShow: true }))} sx={{ position: 'relative' }}>
      {!!commentCount && (
        <Box
          sx={{
            position: 'absolute',
            right: '-8px',
            top: '-8px',
            height: '16px',
            width: '16px',
            borderRadius: '50%',
            backgroundColor: (theme) => theme.palette.color.green1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontWeight: 600,
            fontSize: '10px',
          }}>
          {commentCount}
        </Box>
      )}
      <MessageOutlinedIcon
        fontSize="medium"
        sx={{ cursor: 'pointer', color: selectedId === id ? theme.palette.primary.main : theme.palette.color.grey2 }}
      />
    </Box>
  );
};

export default CommentView;
