import { Box, styled, Tab, TableCell, Tabs } from '@mui/material';
import { OPTION_NONE } from 'common/defines/constants';
import { formatAndSortPopulationCountData } from 'common/utils/fish-migration';
import { QUERY_KEY } from 'constants/constants';
import { IPopulationCount } from 'interfaces/fish-migration';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllPopulationCountAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeFilteredEndDate,
  changeFilteredStartDate,
  changePopulationCountTab,
  fishMigrationSelector,
} from 'store/slices/map-view/fishMigrationAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import CustomTabPanel from './components/CustomTabPanel';
import PopulationTab from './PopulationTab';
import TrackingTab from './TrackingTab';

export const CustomTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const PopulationCountAnalytics = () => {
  const [populationTableData, setPopulationTableData] = useState<IPopulationCount[]>([]);

  const { analyticId, levelId } = useAppSelector(mapViewSelector);
  const {
    populationCount: { selectedTab },
  } = useAppSelector(fishMigrationSelector);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useQuery([QUERY_KEY.POPULATION_COUNT_ANALYTIC, analyticId], () => getAllPopulationCountAnalytic(analyticId || ''), {
    enabled: !!analyticId,
    onSuccess: (res) => {
      const resData = res.data as IPopulationCount[];
      const formattedData = formatAndSortPopulationCountData(resData);

      setPopulationTableData(formattedData);
    },
  });

  // reset tab value into 0
  useEffect(() => {
    return () => {
      dispatch(changePopulationCountTab(0));
    };
  }, [dispatch]);

  // initial and reset startDate and endDate each time change levelId
  useEffect(() => {
    dispatch(
      changeFilteredStartDate({
        label: OPTION_NONE,
        value: '',
      })
    );

    dispatch(
      changeFilteredEndDate({
        label: OPTION_NONE,
        value: '',
      })
    );
  }, [dispatch, levelId]);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(changePopulationCountTab(newValue));
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        height: 'fit-content',
        width: '100%',
        borderRadius: '8px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        padding: '12px',
      }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={t('trans.population')} {...a11yProps(0)} />
          <Tab label={t('trans.tracking')} {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTab} index={0}>
        <PopulationTab populationTableData={populationTableData} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <TrackingTab populationTableData={populationTableData} />
      </CustomTabPanel>
    </Box>
  );
};

export default PopulationCountAnalytics;
