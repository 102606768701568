import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { distance as turfDistance, point } from '@turf/turf';
import {
  extractFishSpeciesName,
  generateFilteredDataWithDate,
  generateGroupingDataWithPeriod,
  groupPopulationCountWithTagId,
} from 'common/utils/fish-migration';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import { IPopulationCount, ITrackingTableData } from 'interfaces/fish-migration';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';
import { CustomTableBodyCell, CustomTableHeaderCell } from '../components/CustomTableCell';
import TrackingTableDetailModal from './TrackingTableDetailModal';

interface TrackingTableProps {
  filteredPopulationData: IPopulationCount[];
}

const TrackingTable: FC<TrackingTableProps> = ({ filteredPopulationData }) => {
  const [isShowTrackingTableDetailModal, setIsShowTrackingTableDetailModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const {
    populationCount: {
      filteredStartDate: { value: selectedStartDate },
      filteredEndDate: { value: selectedEndDate },
    },
  } = useAppSelector(fishMigrationSelector);

  const filteredDataWithDate = generateFilteredDataWithDate(filteredPopulationData, selectedStartDate, selectedEndDate);
  const populationCountGroupedWithTagId = groupPopulationCountWithTagId(filteredDataWithDate);
  const groupDataWithPeriod = generateGroupingDataWithPeriod(populationCountGroupedWithTagId);

  const trackingTableData: ITrackingTableData[] = groupDataWithPeriod.map((item) => {
    const first = item[0];
    const last = item[item.length - 1];

    const { tagId, image } = first;

    const startDate = moment(first.date).format(DATE_VISUALIZE_FORMAT);
    const endDate = moment(last.date).format(DATE_VISUALIZE_FORMAT);

    const duration = moment(last.date).diff(first.date, 'days');

    const startPoint = point([first.lon, first.lat]);
    const endPoint = point([last.lon, last.lat]);
    const calculateDistance = turfDistance(startPoint, endPoint, { units: 'kilometers' });
    const distance = Number(calculateDistance.toFixed(2));

    const species = extractFishSpeciesName(image);

    return { tagId, species, startDate, endDate, duration, distance, startPoint: first, endPoint: last };
  });

  return (
    <Box>
      <TableContainer component={Paper} sx={{ mt: '16px', position: 'relative' }}>
        <Tooltip title="Extends Table">
          <CropFreeOutlinedIcon
            sx={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }}
            onClick={() => setIsShowTrackingTableDetailModal(true)}
          />
        </Tooltip>

        <Table>
          <TableHead>
            <TableRow>
              <CustomTableHeaderCell>{t('trans.id')}</CustomTableHeaderCell>
              <CustomTableHeaderCell>{t('trans.species')}</CustomTableHeaderCell>
              <CustomTableHeaderCell>{t('trans.start_date')}</CustomTableHeaderCell>
              <CustomTableHeaderCell>{t('trans.end_date')}</CustomTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trackingTableData.map((row, index) => {
              const { tagId, species, startPoint, endPoint } = row;

              const startDate = moment(startPoint.date).format(DATE_VISUALIZE_FORMAT);
              const endDate = moment(endPoint.date).format(DATE_VISUALIZE_FORMAT);

              return (
                <TableRow key={index}>
                  <CustomTableBodyCell>{tagId}</CustomTableBodyCell>
                  <CustomTableBodyCell>{species}</CustomTableBodyCell>
                  <CustomTableBodyCell sx={{ width: '105px' }}>{startDate}</CustomTableBodyCell>
                  <CustomTableBodyCell sx={{ width: '105px' }}>{endDate}</CustomTableBodyCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {isShowTrackingTableDetailModal && (
        <TrackingTableDetailModal
          handleClose={() => setIsShowTrackingTableDetailModal(false)}
          trackingTableData={trackingTableData}
        />
      )}
    </Box>
  );
};

export default TrackingTable;
