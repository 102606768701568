import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodEnum } from 'common/defines/clients';
import { OPTION_ALL, OPTION_NONE, PopulationCountTabEnum } from 'common/defines/constants';
import { RootState } from 'store/store';

interface IHeatmapStatus {
  levelId: string;
  isShowHeatmap: boolean;
}

interface IAnimationStatus {
  levelId: string;
  isAnimate: boolean;
}

interface IListSelected {
  label: string[];
  value: string[];
}

interface IFilteredDate {
  label: string;
  value: string;
}

interface IFocusTag {
  info: {
    id: string;
    long: number;
    lat: number;
  };
  isFlyDone: boolean;
}

interface IFishMigration {
  populationCount: {
    focusTag: IFocusTag;
    listSelectedIds: IListSelected;
    filteredStartDate: {
      label: string;
      value: string;
    };
    filteredEndDate: {
      label: string;
      value: string;
    };
    flightMissionDates: IListSelected;
    selectedTab: PopulationCountTabEnum;
    animationStatus: IAnimationStatus[];
    heatmapStatus: IHeatmapStatus[];
  };
  aquaticSpeciesTracking: {
    listSelectedIds: number[];
    selectedTimeFrame: PeriodEnum;
  };
}

const initialState: IFishMigration = {
  populationCount: {
    focusTag: {
      isFlyDone: true,
      info: {
        id: '',
        long: 0,
        lat: 0,
      },
    },
    flightMissionDates: {
      label: [OPTION_ALL],
      value: [],
    },
    listSelectedIds: {
      label: [OPTION_ALL],
      value: [],
    },
    filteredStartDate: {
      label: OPTION_NONE,
      value: '',
    },
    filteredEndDate: {
      label: OPTION_NONE,
      value: '',
    },
    selectedTab: PopulationCountTabEnum.POPULATION,
    animationStatus: [],
    heatmapStatus: [],
  },
  aquaticSpeciesTracking: {
    listSelectedIds: [],
    selectedTimeFrame: PeriodEnum.MAX,
  },
};

export const fishMigrationSlice = createSlice({
  name: 'fishMigration',
  initialState,
  reducers: {
    changeFocusedTagState: (state, action: PayloadAction<IFocusTag>) => {
      state.populationCount.focusTag = action.payload;
    },
    changePopulationListSelectedIds: (state, action: PayloadAction<IListSelected>) => {
      state.populationCount.listSelectedIds = action.payload;
    },
    changeFlightMissionDates: (state, action: PayloadAction<IListSelected>) => {
      state.populationCount.flightMissionDates = action.payload;
    },
    changeFilteredStartDate: (state, action: PayloadAction<IFilteredDate>) => {
      state.populationCount.filteredStartDate = action.payload;
    },
    changeFilteredEndDate: (state, action: PayloadAction<IFilteredDate>) => {
      state.populationCount.filteredEndDate = action.payload;
    },
    changePopulationCountTab: (state, action: PayloadAction<number>) => {
      state.populationCount.selectedTab = action.payload;
    },
    changeAnimationStatus: (state, action: PayloadAction<IAnimationStatus[]>) => {
      state.populationCount.animationStatus = action.payload;
    },
    changeHeatmapStatus: (state, action: PayloadAction<IHeatmapStatus[]>) => {
      state.populationCount.heatmapStatus = action.payload;
    },

    changeListSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.aquaticSpeciesTracking.listSelectedIds = action.payload;
    },
    changeSelectedTimeFrame: (state, action: PayloadAction<PeriodEnum>) => {
      state.aquaticSpeciesTracking.selectedTimeFrame = action.payload;
    },
  },
});

export const {
  changeFocusedTagState,
  changeFlightMissionDates,
  changePopulationListSelectedIds,
  changeFilteredStartDate,
  changeFilteredEndDate,
  changePopulationCountTab,
  changeAnimationStatus,
  changeHeatmapStatus,

  changeListSelectedIds,
  changeSelectedTimeFrame,
} = fishMigrationSlice.actions;

export const fishMigrationSelector = (state: RootState) => state.fishMigration;

export default fishMigrationSlice.reducer;
