import { TFunction } from 'i18next';

const convertTextToMultiLanguage = (text: string, module: string) => {
  if (!text) return '';
  const translationKey = text.toLowerCase().split(' ').join('_');
  const fullTranslationKey = `${module || 'trans'}.${translationKey}`;
  return fullTranslationKey;
};

const convertAnalyticNameToMultiLanguage = (analyticName: string) => {
  if (!analyticName) return '';
  return convertTextToMultiLanguage(analyticName, 'analytic');
};

const convertCropTypeToMultiLanguage = (cropType: string) => {
  if (!cropType) return '';
  return convertTextToMultiLanguage(cropType, 'cropType');
};

const convertLabelToMultiLanguage = (label: string, t: TFunction<'translation', undefined>) => {
  if (!label) return '';
  const textList = label.toLowerCase().split(' ');
  const numberItem = textList.filter((item) => !isNaN(parseFloat(item)) && item.trim() !== '')?.[0];
  const withoutNumberList = textList.filter((item) => !(!isNaN(parseFloat(item)) && item.trim() !== ''));
  const translatedValue = t(`trans.${withoutNumberList.join('_')}`);
  const resultText = `${translatedValue} ${numberItem}`;
  return resultText;
};

export {
  convertCropTypeToMultiLanguage,
  convertAnalyticNameToMultiLanguage,
  convertTextToMultiLanguage,
  convertLabelToMultiLanguage,
};
