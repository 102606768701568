import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IMetadata } from 'common/defines/clients';
import { generateListCheckedLastLevelIds } from 'common/utils/erms';
import PaginationCommon from 'components/Common/PaginationCommon';
import { QUERY_KEY } from 'constants/constants';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import {
  IHarvestTransactionResponse,
  ILinkedHarvestTableData,
  ITreeNodeCheckbox,
  LinkStatusEnum,
} from 'interfaces/erms';
import { get } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { deleteLinkedHarvest, getHarvestTableData } from 'services/erms';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeTriggerCallTransactionTable, ermsSelector } from 'store/slices/ermsSlice';
import CommonContainer from '../components/CommonContainer';
import ConfirmTagModal from '../components/ConfirmTagModal';
import CustomTableCell from '../components/CustomTableCell';

interface WeighbridgeTransactionTableProps {
  treeData: ITreeNodeCheckbox[];
}

const LinkedHarvestTransactionTable: FC<WeighbridgeTransactionTableProps> = ({ treeData }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { filterDate, trigger } = useAppSelector(ermsSelector);
  const [linkedHarvestMetadata, setLinkedHarvestMetadata] = useState<IMetadata>();
  const [linkedHarvestTableData, setLinkedHarvestTableData] = useState<ILinkedHarvestTableData[]>([]);

  const [deleteLinkId, setDeleteLinkId] = useState<string>('');
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);

  const getListCheckedLastLevelIds = generateListCheckedLastLevelIds(treeData);

  const linkHarvestTransactionRequest = {
    perPage: 10,
    currentPage: currentPage,
    linkStatus: LinkStatusEnum.LINK,
    levelIds: getListCheckedLastLevelIds,
    fromDate: moment(filterDate.startDate).utc(true).toISOString(),
    toDate: moment(filterDate.endDate).utc(true).toISOString(),
  };

  const { refetch: refetchLinkTransaction } = useQuery<IHarvestTransactionResponse>(
    [QUERY_KEY.GET_LINKED_HARVEST_TRANSACTION_TABLE, currentPage],
    () => getHarvestTableData(linkHarvestTransactionRequest),
    {
      enabled: !!getListCheckedLastLevelIds.length,
      retry: 0,
      keepPreviousData: false,
      onSuccess(res) {
        setLinkedHarvestMetadata(res.metadata);
        const summaryWeighbridgeData = res.summaryHarvestByWeighbridgeId;
        const convertHarvestTableData = res.docs.map((item) => {
          const { hvRipe, hvOverripe, hvUnderripe, links } = item;
          const [{ weighbridgeId, weighbridgeNetWeight }] = links;
          const paidFFB = hvRipe + hvOverripe + hvUnderripe;

          const foundedWeighbridge = summaryWeighbridgeData.find((ele) => ele.weighbridgeId === weighbridgeId);

          const totalFFBWithWeighbridgeId = foundedWeighbridge?.totalFFB || 1;
          const averageWeight = weighbridgeNetWeight / totalFFBWithWeighbridgeId;
          const totalWeight = averageWeight * paidFFB;

          return { ...item, paidFFB, averageWeight, totalWeight };
        });

        setLinkedHarvestTableData(convertHarvestTableData);
      },
      onError() {
        setLinkedHarvestTableData([]);
        setLinkedHarvestMetadata(undefined);
      },
    }
  );

  useEffect(() => {
    const { isCallLinkedHarvestTransactionTable } = trigger;
    if (isCallLinkedHarvestTransactionTable) {
      refetchLinkTransaction();
      dispatch(changeTriggerCallTransactionTable({ isCallLinkedHarvestTransactionTable: false }));
    }
  }, [dispatch, refetchLinkTransaction, trigger]);

  const deleteLinkMutation = useMutation(deleteLinkedHarvest, {
    onSuccess() {
      toast.success(t('erms.delete_link_successfully'));
      refetchLinkTransaction();
      setIsShowConfirmModal(false);
    },
  });

  return (
    <CommonContainer style={{ px: 3, py: 1 }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography component="h1" fontSize="20px" fontWeight={500}>
          {t('erms.linked_harvest_transaction')}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Scrollbars autoHeight autoHide autoHeightMax={'500px'}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <CustomTableCell>{t('erms.harvest_date')}</CustomTableCell>
                <CustomTableCell>{t('erms.location')}</CustomTableCell>
                <CustomTableCell>{t('erms.paid_ffb')}</CustomTableCell>
                <CustomTableCell>{t('erms.average_weight_tonne')}</CustomTableCell>
                <CustomTableCell>{t('erms.total_weight_tonne')}</CustomTableCell>
                <CustomTableCell>{t('erms.harvester_name')}</CustomTableCell>
                <CustomTableCell>{t('erms.linked_tag')}</CustomTableCell>
                <CustomTableCell>{t('erms.action')}</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linkedHarvestTableData.map((row, index) => {
                const { hvDate, estateName, divisionName, blockName, groupName, paidFFB, averageWeight, totalWeight } =
                  row;
                return (
                  <TableRow key={index}>
                    <TableCell>{moment(hvDate).format(DATE_VISUALIZE_FORMAT)}</TableCell>
                    <TableCell>{`${estateName}${divisionName ? `/${divisionName}` : ''}${
                      blockName ? `/${blockName}` : ''
                    }`}</TableCell>
                    <TableCell>{paidFFB}</TableCell>
                    <TableCell>{averageWeight.toFixed(3)}</TableCell>
                    <TableCell>{totalWeight.toFixed(3)}</TableCell>
                    <TableCell>{groupName}</TableCell>
                    <TableCell>{get(row, 'links[0].tag', '')}</TableCell>
                    <TableCell>
                      <Button
                        color="error"
                        variant="text"
                        onClick={() => {
                          setIsShowConfirmModal(true);
                          setDeleteLinkId(get(row, 'links[0]._id', ''));
                        }}>
                        {t('trans.delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbars>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '16px' }}>
        <PaginationCommon
          currentPage={currentPage}
          onChangePage={(val) => setCurrentPage(val)}
          totalPage={linkedHarvestMetadata?.totalPage || 1}
        />
      </Box>

      {isShowConfirmModal && (
        <ConfirmTagModal
          content="Unlink Harvest Transaction with this tag?"
          handleClose={() => {
            setIsShowConfirmModal(false);
            setDeleteLinkId('');
          }}
          handleClickYes={() => {
            deleteLinkMutation.mutate(deleteLinkId);
            setDeleteLinkId('');
          }}
        />
      )}
    </CommonContainer>
  );
};

export default LinkedHarvestTransactionTable;
