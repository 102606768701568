import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker } from 'react-map-gl';
import PopupParagraph from './Common/PopupParagraph';

interface PopulationCountPopupProps {
  hoverInfo: any;
}

const PopulationCountPopup: FC<PopulationCountPopupProps> = ({ hoverInfo }) => {
  const { t } = useTranslation();

  if (!hoverInfo) return null;

  const { properties } = hoverInfo || {};
  const { distance, duration } = properties || {};

  return (
    <Marker
      longitude={properties?.longX}
      latitude={properties?.latY}
      anchor={'bottom'}
      style={{ zIndex: 10 }}
      offset={[0, -20]}>
      <Box
        sx={{
          width: '180px',
          position: 'absolute',
          top: '-40px',
          left: '-90px',
          backgroundColor: (theme) => theme.palette.background.default,
          border: 0,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '12px',
          padding: '8px 12px',
          zIndex: 100,
        }}>
        {distance && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <PopupParagraph value={`${t('trans.distance')}: `} variant="label" />
            <PopupParagraph value={`${Number(distance.toFixed(2))} km`} variant="content" />
          </Box>
        )}
        {duration && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <PopupParagraph value={`${t('trans.duration')}: `} variant="label" />
            <PopupParagraph
              value={`${duration} ${duration === 1 ? t('trans.day') : t('trans.days')}`}
              variant="content"
            />
          </Box>
        )}
      </Box>
    </Marker>
  );
};

export default PopulationCountPopup;
