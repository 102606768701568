import { Box, Typography } from '@mui/material';
import { listHeatMapColors } from 'common/defines/constants';
import { IPopulationCount } from 'interfaces/fish-migration';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Source } from 'react-map-gl';

interface HeatMapDisplayProps {
  filteredData: IPopulationCount[];
}

const HeatMapDisplay: FC<HeatMapDisplayProps> = ({ filteredData }) => {
  const { t } = useTranslation();

  const generateHeatMapSequence = (colors: string[]) => {
    const result = [];

    // Loop through 0 to 1 in steps of 0.1
    for (let i = 1; i <= 10; i = i + 1) {
      // Add the numeric value (0, 0.1, 0.2, etc.)
      result.push(i / 10);

      // Add the corresponding color if available
      if (colors[i]) {
        result.push(colors[i]);
      }
    }
    return result;
  };

  const generateGradientString = (colors: string[]) => {
    // Start with the base of the linear gradient
    let gradientString = 'linear-gradient(90deg,';

    // Loop through the colors array
    colors.forEach((color, index) => {
      // Calculate the percentage for this color
      const percentage = index * 10;

      // Add the color and percentage to the string
      gradientString += ` ${color} ${percentage}%`;

      // Add comma if it's not the last color
      if (index < colors.length - 1) {
        gradientString += ',';
      }
    });

    // Add the closing parenthesis
    gradientString += ')';

    return gradientString;
  };

  const gradient = generateGradientString(listHeatMapColors.slice(1));

  const sequence = generateHeatMapSequence(listHeatMapColors);

  const heatmapLayer: any = {
    id: 'heatmap_population_count',
    type: 'heatmap',
    maxzoom: 20,
    paint: {
      'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 10, 1],
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 20, 3],
      'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, 'rgba(0,0,0,0)', ...sequence],
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 20, 50],
      'heatmap-opacity': 1,
    },
  };

  const heatMapGeojsonData: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: filteredData.map((item) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.lon, item.lat],
        },
        properties: {
          color: item.color,
          mag: 1,
        },
      };
    }),
  };
  return (
    <Box>
      <Source id="heatmapData" type="geojson" data={heatMapGeojsonData}>
        <Layer {...heatmapLayer} />
      </Source>
      <Box
        sx={{
          position: 'absolute',
          top: '20px',
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
        }}>
        <Box sx={{ width: '600px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Box
            sx={{
              height: '20px',
              background: gradient,
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {['trans.low', 'trans.middle', 'trans.high'].map((item) => (
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  padding: '4px',
                  borderRadius: '4px',
                }}>
                <Typography key={item}>{t(item)}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeatMapDisplay;
